.app__partner-carousel .carousel .control-arrow,
.app__partner-carousel .carousel .carousel-slider .control-arrow {
    -webkit-transition:all .25s;
    -moz-transition:all .25s;
    -ms-transition:all .25s;
    -o-transition:all .25s;
    transition:all .25s;
    opacity: 0.6 !important;
    filter: alpha(opacity=60);
    background: transparent !important;
}

.app__partner-carousel .carousel .control-prev.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-white) !important;
    border-width: 8px 8px 0 0 !important;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.app__partner-carousel .carousel .control-next.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-white) !important;
    border-width: 8px 8px 0 0 !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.app__partner {
    width: 100%;
    min-height: 60rem;
    background-color: var(--color-royalblue);
    position: relative;
    z-index: 1;
    text-align: left;
    overflow: hidden;
}

#partner__mobius {
    background-color: transparent;
    background-image: linear-gradient(270deg, rgba(147, 18, 169, 1), rgb(0, 74, 128));
}

.snaplogic__monogram_top {
    width: 30rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.snaplogic__monogram_bottom {
    width: 30rem;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.mobius__monogram_top {
    width: 60rem;

    position: absolute;
    top: -8%;
    right: -18%;
    z-index: -1;
}

.mobius__monogram_bottom {
    width: 45rem;

    position: absolute;
    bottom: -8%;
    left: -18%;
    z-index: -1;
}

.app__partner-title {
    color: var(--color-white);
}

.app__partner-logo {
    width: 10%;
    margin: 3rem 0 0;
}

.app__partner-description {
    color: var(--color-white);
}

.app__partner-subheading {
    margin: 3rem 0 1rem;
    color: var(--color-white);
    font-weight: 600;
}

.app__partner-coursecontainer {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.app__partner-button,
.app__courses-button {
    margin: 2rem auto 0;
    display: block;
    border-radius: 4px;
    transition: 0.1s;
}

.app__partner-button button,
.app__courses-button button {
    margin: auto;
    padding: 0.8rem 1.5rem;
    color: var(--color-royalblue);
    background-color: var(--color-white);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 0;
    cursor: pointer;
    transition: 0.1s;
}

.app__courses-button button {
    color: var(--color-white);
    background-color: var(--color-blue);
}

.app__partner-button button:hover,
.app__courses-button button:hover {
    transform: scale(1.01);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.app__partner-button button:active,
.app__courses-button button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.app__partner-button button .dropdown_arrow,
.app__courses-button .dropdown_arrow {
    margin-left: 0.5rem;
    color: var(--color-royalblue);
    line-height: 0;
}

.app__courses-button button .dropdown_arrow {
    color: var(--color-white);
}



@media screen and (min-width: 2000px) {
    .app__partner {
        min-height: 80rem;
    }

    .app__partner-logo {
        width: 12%;
    }
    
    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
        font-size: 22px;
    }
}



@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .app__partner {
        min-height: 60rem;
    }

    .app__partner-logo {
        width: 12%;
    }

    .app__partner-button button,
    .app__courses-button button {
        font-size: 18px;
    }
}



@media screen and (max-width: 1440px) {
    .app__partner {
        min-height: 56rem;
    }
}



@media screen and (min-width: 1151px) and (max-width: 1400px) {
    .app__partner-logo {
        width: 14%;
    }

    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
        font-size: 18px;
    }
}



@media screen and (max-width: 1280px) {
    .app__partner-button button {
        padding: 0.6rem 1.5rem;
    }

    .app__partner-button button > .dropdown_arrow {
        font-size: 1rem !important;
    }
}



@media screen and (max-width: 1150px) {
    .app__partner {
        min-height: 85rem;
    }

    .app__partner-logo {
        width: 18%;
    }
    
    .app__partner-button button {
        padding: 0.8rem 1.5rem;
        font-size: 16px;
    }

    .app__courses-button button {
        padding: 1rem 1.5rem;
        font-size: 16px;
    }

    .app__partner-button button > .dropdown_arrow {
        font-size: 1.8rem !important;
    }

    .app__courses-button button > .dropdown_arrow {
        font-size: 1.6rem !important;
    }
}



@media screen and (max-width: 1000px) {
    .app__partner {
        min-height: 83rem;
    }
}



@media screen and (max-width: 850px) {
    .mobius__monogram_top {
        width: 50rem;

        top: -8%;
        right: -50%;
    }

    .app__partner {
        min-height: 78rem;
    }
    
    .app__partner-logo {
        width: 26%;
    }

    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
    }
}



@media screen and (max-width: 650px) {
    .app__partner {
        min-height: 78rem;
    }
    
    .app__partner-logo {
        width: 32%;
        margin: 2rem 0 0;
    }

    .app__partner-button {
        margin: 3rem auto 0;
    }

    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
        font-size: 16px;
    }

    .app__partner-button button > .dropdown_arrow {
        font-size: 2rem !important;
    }
}



@media screen and (max-width: 580px) {
    .app__partner {
        min-height: 78rem;
    }
}



@media screen and (max-width: 480px) {
    .app__partner {
        min-height: 84rem;
    }

    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
        font-size: 14px;
    }

    .app__partner-button button > .dropdown_arrow {
        font-size: 1.6rem !important;
    }

    .app__courses-button button > .dropdown_arrow {
        font-size: 1.4rem !important;
    }
}



@media screen and (max-width: 450px) {
    .app__partner {
        min-height: 136rem;
    }

    .app__partner-logo {
        width: 44%;
    }

    .app__courses-button {
        margin: 1rem auto 3rem;
    }

    .app__partner-button button,
    .app__courses-button button {
        padding: 0.8rem 1.5rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 360px) {
    .app__partner {
        min-height: 110rem;
    }

    .app__partner-button button,
    .app__courses-button button {
        font-size: 12px;
    }
}