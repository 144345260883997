.formcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-main);
    height: 100vh;
}

.formcontainer form{
    margin-top: 2rem;
    width: 38%;
}



.formcontainer form ,.formContainer form div{
    display: flex;
    flex-direction: column;
}

.formcontainer form input,.formcontainer form div input,.formcontainer input{
    margin-top: 0.2rem;
    height: 32px;
    padding: 0.2rem 0.5rem;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.formcontainer form label,.formcontainer form div label{
     margin-top: 0.2rem;
}

.bttnContainer{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 1rem;
}
.bttnContainer button {
    cursor: pointer;
    border-radius: 4px;
    font-family: "Poppins";
    color: white;
    background-color: #1694d1;
    transition: 0.5s;
    width: 100px;
    height: 2rem;
    border: 0;
    outline: 0;
}

.bttnContainer button:hover {
    background-color: #106f9c;
    transition: 0.5s;
}

.app_input_pass{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    column-gap:3px ;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}
.app_input_pass input{
    width: 97%;
    height: 28px;
    border:none !important;
    box-shadow:none !important;
}
.app_input_pass input[type=text]:focus,
.app_input_pass input[type=password]:focus,
.app_input_pass input[type=text]:focus-visible,
.app_input_pass input[type=password]:focus-visible
{
    border: none !important
}
.app_input_pass_eye {
    cursor: pointer;
    width: 2.7%;
    padding: 0.2rem 0.5rem;

}

@media screen and (width< 480px) {
   .formContainer form {
        width: 90%;
    }

    .formcontainer form input {
        height: 20px;
    }
}
@media screen and (width< 980px) {
   .formcontainer form {
        width: 80%;
    }

    .formcontainer form input, .otherInfo div input {
        height: 34px;
    }
}