.app__features {
    width: 100%;
    background-color: var(--color-white);
}

.app__features .app__featurecard {
    margin-right: 5%;
}

.app__features .app__featurecard:nth-child(3n) {
    margin-right: 0;
}

.app__features-heading {
    width: 100%;
    text-align: left;
}

.app__features-container {
    width: 100%;
    margin-top: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}



@media screen and (max-width: 1350px) {
    .app__features .app__featurecard {
        margin-right: 0.5%;
    }
}



@media screen and (max-width: 1150px) {
    .app__features-container {
        width: 100%;
        margin-top: 1rem;
    }
}



@media screen and (max-width: 850px) {
    .app__features-heading {
        margin-left: 1.5rem;
    }
}



@media screen and (max-width: 650px) {
    .app__features .app__featurecard {
        margin-right: 0;
    }
}



@media screen and (max-width: 450px) {
    .app__features-heading {
        margin-left: 0.8rem;
    }
}
