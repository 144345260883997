.app__mycourses {
    width: 100%;
}

.app__mycourses-heading {
    margin: 0 0 3rem;
    color: var(--color-blue);
}

.app__mycourses-registeredcoursecontainer {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.app__mycourses-coursecontainer {
    width: 100%;

    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; */
}

.app__mycourses-registeredcoursemessage {
    margin: -1rem 0 5rem;
}

.app__mycourses-coursecontainer .react-multi-carousel-item{
    margin-right: 2rem !important;
}



@media screen and (min-width: 2000px) {
    .app__mycourses-coursecontainer .react-multi-carousel-item{
        margin-right: 5rem !important;
    }
}



@media screen and (max-width: 1680px) {
    .app__mycourses-coursecontainer .react-multi-carousel-item{
        margin-right: 3rem !important;
    }
}



@media screen and (max-width: 1440px) {
    .app__mycourses-coursecontainer .react-multi-carousel-item{
        margin-right: 2rem !important;
    }
}



@media screen and (max-width: 1380px) {
    .app__mycourses-heading {
        margin: 0 0 2rem;
    }

    .app__mycourses-coursecontainer .react-multi-carousel-item{
        margin-right: 3rem !important;
    }
}



@media screen and (max-width: 1150px) {
    .app__mycourses-coursecontainer .react-multi-carousel-item{
        margin-right: 1rem !important;
    }
}
