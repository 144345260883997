.app__illustrationcard {
    width: 30%;
    min-height: 200px;
    margin-bottom: 2rem;
    flex-direction: column;
    border-radius: 6px;
    /* background-color: var(--color-white); */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: rgba(123, 185, 221, 0.2) 0px 10px 20px, rgba(123, 185, 221, 0.1) 0px 6px 6px; */
    transform: 0.1s;
}

.app__illustrationcard_illustration {
    width: 80px;
    margin: 1rem 0 1rem;
}

.app__illustrationcard_title {
    width: 70%;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}



@media screen and (min-width: 2000px) {
    .app__illustrationcard {
        min-height: 280px;
        margin-bottom: 0rem;
        border-radius: 8px;
    }

    .app__illustrationcard_illustration {
        width: 100px;
        margin: 0rem 0 1rem;
    }

    .app__illustrationcard_title {
        width: 80%;
        font-size: 20px;
        line-height: 28px;
    }
}



@media screen and (max-width: 1680px) {
    .app__illustrationcard {
        width: 32%;
        min-height: 170px;
        margin-bottom: 1rem;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1500px) {
    .app__illustrationcard {
        width: 32%;
        min-height: 160px;
        margin-bottom: 0rem;
    }

    .app__illustrationcard_title {
        font-size: 16px;
        line-height: 22px;
    }

    .app__illustrationcard_illustration {
        width: 70px;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1280px) {
    .app__illustrationcard {
        width: 32%;
        min-height: 160px;
    }

    .app__illustrationcard_title {
        font-size: 15px;
        line-height: 20px;
    }

    .app__illustrationcard_illustration {
        width: 70px;
    }
}



@media screen and (max-width: 1150px) {
    .app__illustrationcard {
        min-height: 60px;
    }

    .app__illustrationcard_illustration {
        width: 50px;
    }
    
    .app__illustrationcard_title {
        width: 80%;
        font-size: 14px;
        line-height: 18px;
    }
}



@media screen and (max-width: 850px) {
    .app__illustrationcard {
        width: 45%;
        min-height: 50px;
    }

    .app__illustrationcard_illustration {
        width: 60px;
    }
    
    .app__illustrationcard_title {
        font-size: 14px;
        line-height: 20px;
    }
}



@media screen and (max-width: 650px) {
    .app__illustrationcard {
        width: 30%;
        min-height: 60px;
        margin: 0 0rem 1rem;
    }

    .app__illustrationcard_illustration {
        width: 60px;
    }
    
    .app__illustrationcard_title {
        font-size: 15px;
        line-height: 18px;
    }
}



@media screen and (max-width: 500px) {
    .app__illustrationcard {
        width: 30%;
        min-height: 60px;
        margin: 0 0rem 1rem;
    }

    .app__illustrationcard_illustration {
        width: 60px;
    }
    
    .app__illustrationcard_title {
        font-size: 12px;
        line-height: 16px;
    }
}



@media screen and (max-width: 450px) {
    .app__illustrationcard {
        width: 30%;
        min-height: 150px;
        margin: 0 0rem 1rem;
    }

    .app__illustrationcard_title {
        font-size: 14px;
        line-height: 18px;
    }
}



@media screen and (max-width: 400px) {
    .app__illustrationcard {
        min-height: 60px;
    }

    .app__illustrationcard_illustration {
        width: 50px;
    }

    .app__illustrationcard_title {
        font-size: 10px;
        line-height: 12px;
    }
}



@media screen and (max-width: 350px) {   
    .app__illustrationcard_title {
        font-size: 10px;
        line-height: 14px;
    }
}
