.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    -webkit-transition:all .25s;
    -moz-transition:all .25s;
    -ms-transition:all .25s;
    -o-transition:all .25s;
    transition:all .25s;
    opacity: 0.6 !important;
    filter: alpha(opacity=60);
    background: transparent !important;
}

.carousel .control-arrow:hover,
.carousel.carousel-slider .control-arrow:hover {
    opacity: 1 !important;
    filter: alpha(opacity=100);
}

.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
    margin-top: -25rem !important;
}

.carousel .control-prev.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-white) !important;
    border-width: 8px 8px 0 0 !important;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.carousel .control-next.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-white) !important;
    border-width: 8px 8px 0 0 !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.carousel.carousel-slider .control-arrow {
    color: var(--color-blue) !important;
    font-size: 26px;
    padding: 20px !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

.carousel .control-dots .dot {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
    border: 2px solid var(--color-blue) !important;
    border-radius: 10px !important;
    width: 30px !important;
    height: 10px !important;
}

.carousel .control-dots .dot:hover {
    border: 2px solid var(--color-lightblue) !important;
    background: var(--color-lightblue) !important;
}

.carousel .control-dots .dot.selected {
    border: 2px solid var(--color-blue) !important;
    background: var(--color-blue) !important;
}



@media screen and (min-width: 2000px) {
    .carousel .control-prev.control-arrow,
    .carousel .control-next.control-arrow {
        margin-top: -35rem !important;
    }
}