.app__footer {
    width: 100%;
    padding-bottom: 3rem;
    background-color: var(--color-lightblue);
    flex-direction: column;
}

.app__footer_content {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.app__footer_content-credentials {
    width: 35%;
}

.app__footer_content-credentials p {
    width: 100%;
    height: 100%;
}

.app__footer_content-tpa,
.app__footer_content-more,
.app__footer_content-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.app__footer_content-tpa {
    width: 28%;
}

.app__footer_content-more {
    width: 10%;
}

.app__footer_content-contact {
    width: 18%;
}

.app__footer_content-credentials-company {
    width: 70%;
    margin: 0rem 0.8rem 0 1.8rem;
}

.app__footer_content-credentials-socialmedia {
    width: 60%;
    margin: 1rem 0 0 4rem;
    justify-content: space-evenly;
}

.app__footer_content-credentials-socialmedia a {
    width: 12%;
    transition: 0.1s;
}

.app__footer_content-credentials-socialmedia a:hover {
    transform: scale(1.2);
    transition: 0.3s;
}

.app__footer_content-credentials-company p,
.app__footer_content-tpa p,
.app__footer_content-more p,
.app__footer_content-contact p {
    margin-bottom: 1.5rem;
    color: var(--color-blue);
    font-weight: 600;
}

.app__footer_content-tpa a,
.app__footer_content-more a,
.app__footer_content-contact a  {
    width: max-content;
    margin-bottom: 0.5rem;
    color: var(--color-black);
}

.app__footer_content-tpa a:hover,
.app__footer_content-more a:hover,
.app__footer_content-contact a:hover {
    color: var(--color-royalblue);
    font-weight: 600;
}

.horizontal__partition {
    width: 100%;
    height: 2px;
    margin: 4rem 0 1rem;
    background-color: var(--color-black);
}

.app__footer_credits {
    width: 100%;
    text-align: center;
    font-size: 18px;
}



@media screen and (min-width: 2000px) {
    .app__footer_content-credentials-socialmedia {
        margin: 1rem 0 0 5rem;
    }

    .app__footer_credits {
        font-size: 24px;
    }
}



@media screen and (min-width: 1200px) and (max-width: 1580px) {
    .app__footer_content-credentials-socialmedia {
        width: 70%;
        margin: 1rem 0 0 2rem;
    }
}



@media screen and (max-width: 1200px) {
    .app__footer_content {
        flex-wrap: wrap;
        justify-content: center;
    }

    .app__footer_content-credentials {
        width: max-content;
        margin: 3rem 0 0 15rem;
        order: 4;
    }

    .app__footer_content-credentials-company {
        width: 55%;
    }

    /* .app__footer_content-credentials p {
        width: 100%;
        height: 100%;
    } */

    .app__footer_content-credentials-socialmedia {
        width: 45%;
        margin: 1rem 0 0 5rem;
    }

    .app__footer_content-tpa {
        width: 52%;
    }
    
    .app__footer_content-more {
        width: 23%;
    }
    
    .app__footer_content-contact {
        width: 25%;
    }

    .horizontal__partition {
        height: 2px;
        margin: 3rem 0 1rem;
    }
}



@media screen and (max-width: 850px) {
    .app__footer_content-credentials {
        width: max-content;
        margin: 2rem 0 0 10rem;
    }

    .app__footer_content-credentials-company {
        width: 55%;
    }

    .app__footer_content-tpa {
        width: 55%;
    }
    
    .app__footer_content-more {
        width: 20%;
    }
    
    .app__footer_content-contact {
        width: 23%;
    }
}



@media screen and (max-width: 720px) {
    .app__footer_content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app__footer_content-credentials {
        width: 100%;
        margin: 0;
    }

    .app__footer_content-credentials-company {
        width: 50%;
        margin: auto;
    }

    .app__footer_content-credentials-socialmedia {
        width: 40%;
        margin: 1rem auto 0;
    }

    .app__footer_content-tpa,
    .app__footer_content-more,
    .app__footer_content-contact {
        width: 100%;
        text-align: left;
        margin-bottom: 3rem;
    }

    .app__footer_content-credentials-company p,
    .app__footer_content-tpa p,
    .app__footer_content-more p,
    .app__footer_content-contact p {
        margin-bottom: 1rem;
    }

    /* .app__footer_content-tpa a,
    .app__footer_content-more a,
    .app__footer_content-contact a {
        margin: auto;
    } */
}



@media screen and (max-width: 650px) {
    .app__footer_content-credentials {
        margin-top: 1rem;
    }

    .app__footer_content-credentials-company {
        width: 60%;
    }

    .app__footer_content-credentials-company_logo {
        width: 25%;
    }

    .app__footer_content-credentials-socialmedia {
        width: 50%;
        margin: 0.4rem auto 0;
        justify-content: space-evenly;
    }

    .horizontal__partition {
        height: 2px;
        margin: 2rem 0 1rem;
    }

    .app__footer_credits {
        font-size: 16px;
    }
}



@media screen and (max-width: 480px) {
    .app__footer_content-credentials-company {
        width: 70%;
    }

    .app__footer_content-credentials-socialmedia {
        width: 60%;
    }
}



@media screen and (max-width: 400px) {
    .app__footer_content-credentials-company {
        width: 80%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app__footer_content-credentials-company_logo {
        width: 28%;
    }

    .app__footer_content-credentials-socialmedia {
        width: 70%;
        margin: 0.6rem auto 0;
    }

    .app__footer_credits {
        font-size: 14px;
    }
}
