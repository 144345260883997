.app__underconstruction {
    width: 100%;
    min-height: 100vh;
    background-color: var(--color-white);
    overflow: hidden;

    flex-direction: column;

    position: relative;
    z-index: 1;
}

.app__underconstruction-illustration {
    width: 20%;
}

.app__underconstruction-heading {
    width: 40%;
    margin: 2rem 0 4rem;
    text-align: center;
    font-weight: 500;
    color: var(--color-blue);
    text-transform: initial;
}

.app__underconstruction-button {
    width: 13%;
    /* margin: 5rem auto 0; */
    display: block;
    border-radius: 4px;
    transition: 0.1s;
}

.app__underconstruction-button button {
    width: 100%;
    padding: 1.2rem 0;
    color: var(--color-black);
    background-color: var(--color-white);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
}

.app__underconstruction-button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
}

.app__underconstruction-button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.app__underconstruction-button button:hover {
    background-color: var(--color-blue);
    transition: 0.2s;
}

.vector__illustration_left {
    width: 40%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.vector__illustration_right {
    width: 40%;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}



@media screen and (min-width: 2000px) {
    .app__underconstruction-button {
        width: 16%;
        margin-top: 5rem;
    }

    .app__underconstruction-button button {
        padding: 1.6rem 0;
        font-size: 30px;
    }
}



@media screen and (max-width: 1680px) {
    .app__underconstruction-button {
        width: 17%;
    }
}



@media screen and (max-width: 1500px) {
    .app__underconstruction-illustration {
        width: 40%;
    }

    .app__underconstruction-heading {
        width: 50%;
        margin: 2rem 0 3rem;
    }
    
    .app__underconstruction-button {
        width: 18%;
        margin-top: 0rem;
    }

    .app__underconstruction-button button {
        padding: 1.2rem 0;
        font-size: 18px;
    }
}



@media screen and (max-width: 1380px) {
    .app__underconstruction-illustration {
        width: 35%;
    }

    .app__underconstruction-button {
        width: 16%;
    }

    .app__underconstruction-button button {
        font-size: 16px;
    }
}



@media screen and (max-width: 1150px) {
    .app__underconstruction {
        min-height: auto;
    }
    
    .app__underconstruction-illustration {
        width: 45%;
    }

    .app__underconstruction-heading {
        width: 60%;
    }

    .app__underconstruction-button {
        width: 20%;
    }

    .app__underconstruction-button button {
        padding: 1.2rem 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 1050px) {
    .app__underconstruction-illustration {
        width: 60%;
    }
}



@media screen and (max-width: 850px) {
    .app__underconstruction-illustration {
        width: 60%;
    }

    .app__underconstruction-heading {
        width: 80%;
        margin: 1rem 0 3rem;
    }

    .app__underconstruction-button {
        width: 30%;
    }
}



@media screen and (max-width: 650px) {
    .app__underconstruction-illustration {
        width: 70%;
    }

    .app__underconstruction-heading {
        width: 90%;
    }

    .app__underconstruction-button {
        width: 35%;
    }

    .app__underconstruction-button button {
        padding: 1rem 0;
        font-size: 14px;
    }
}



@media screen and (max-width: 480px) {
    .app__underconstruction-illustration {
        width: 80%;
    }

    .app__underconstruction-button {
        width: 40%;
    }
}



@media screen and (max-width: 420px) {
    .app__underconstruction-illustration {
        width: 100%;
    }

    .app__underconstruction-heading {
        width: 100%;
        margin: 1rem 0 2rem;
    }

    .app__underconstruction-button {
        width: 48%;
    }
}



@media screen and (max-width: 360px) {
    .app__underconstruction-button {
        width: 60%;
    }
}