.formcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-main);
    height: auto;
}

.formcontainer form{
    margin-top: 2rem;
    width: 38%;
}



.formcontainer form div {
    display: flex;
    flex-direction: column;
}

.formcontainer form div input{
     margin-top: 0.2rem;
    height: 32px;
    padding: 0.2rem 0.5rem;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.formcontainer form div label{
     margin-top: 0.2rem;
}

.bttnContainer{
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 1rem;
}
.bttnContainer button {
    cursor: pointer;
    border-radius: 4px;
    font-family: "Poppins";
    color: white;
    background-color: #1694d1;
    transition: 0.5s;
    width: 100px;
    height: 2rem;
    border: 0;
    outline: 0;
}

.bttnContainer button:hover {
    background-color: #106f9c;
    transition: 0.5s;
}



@media screen and (width< 480px) {
   .formContainer form {
        width: 90%;
    }

    .formcontainer form div input {
        height: 20px;
    }
}
@media screen and (width< 980px) {
   .formcontainer form {
        width: 80%;
    }

    .formcontainer form div input, .otherInfo div input {
        height: 34px;
    }
}