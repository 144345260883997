.app__blog {
    width: 100%;
    min-height: calc(100vh - 5rem);
}

.app__blog_subheading {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--color-blue);
    
}
.app__blog_subheading span {
    padding: 0 0.5rem 0.6rem;
    cursor: pointer;
    font-style: initial;
    font-weight: 500;
}

.app__blog_subheading span:first-child {
    margin-right: 3rem;
}

.app__blog_subheading-default {
    color: var(--color-lightgrey)
}

.app__blog_subheading-active {
    color: var(--color-blue);
    border-bottom: 5px solid var(--color-blue);
}

.app__blog_content {
    width: 100%;
    margin: 3rem 3rem 1rem 3rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;    
    flex-wrap: wrap;
    row-gap: 2.4rem;
    column-gap: 2.4rem;
}

.app__blog_content_noevent {
    width: 100%;
    margin: 3rem auto 0;
    text-align: center;
}



@media screen and (min-width: 2000px) {
    .app__blog_content {
        row-gap: 3rem;
        column-gap: 4rem;
    }
}



@media screen and (max-width: 1680px) {
    .app__blog_content {
        row-gap: 2rem;
        column-gap: 2rem;
    }
}



@media screen and (max-width: 1440px) {
    .app__blog_content {
        row-gap: 1.2rem;
        column-gap: 1.2rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__blog_content {
        row-gap: 1.8rem;
        column-gap: 1.8rem;
    }
}



@media screen and (max-width: 850px) {
    .app__blog_subheading span {
        transform: scale(1.2);
    }

    .app__blog_content {
        margin: 3rem 1rem 1rem 1rem;
    }
}



@media screen and (max-width: 650px) {
    .app__blog_content {
        row-gap: 1.5rem;
        column-gap: 1.5rem;
    }

    .app__blog_content_noevent {
        width: 80%;
    }
}



@media screen and (max-width: 480px) {
    .app__blog_subheading span {
        transform: scale(1);
    }

    .app__blog_content {
        row-gap: 2rem;
        column-gap: 0rem;
    }
}



@media screen and (max-width: 420px) {
    .app__blog_content_noevent {
        margin: 2rem 0 0 1rem;
    }
}
