.app__partnership {
    width: 100%;
    background-color: var(--color-lightblue);

    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.app__partnership_partner,
.app__partnership_learn {
    width: calc(50% - 2px);
}

.app__partnership_partner {
    padding-right: 3rem;
}

.app__partnership_learn {
    padding-left: 3rem;
}

.app__partnership_partner-heading,
.app__partnership_learn-heading {
    width: 100%;
    margin-bottom: 3rem;
    text-align: left;
}

.app__partnership_partner-description {
    margin-bottom: 5rem;
}

.app__partnership_partner-subheading {
    width: 100%;
    margin: 2rem 0 0rem;
    text-align: center;
}

.app__partnership_partner-illustrationcards {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.vertical__partition {
    width: 4px;
    height: 64rem;
    margin: 0.6rem 3rem;
    opacity: 0.6;
    background-color: var(--color-blue);
}



@media screen and (min-width: 2000px) {
    .app__partnership_partner,
    .app__partnership_learn {
        width: calc(50% - 3px);
    }

    .app__partnership_partner {
        padding-right: 5rem;
    }
    
    .app__partnership_learn {
        padding-left: 5rem;
    }

    .app__partnership_partner-description {
        margin-bottom: 7rem;
    }

    .app__partnership_partner-heading,
    .app__partnership_learn-heading {
        margin-bottom: 3rem;
    }

    .app__partnership_partner-subheading {
        margin: 4rem 0 3rem;
    }

    .vertical__partition {
        width: 6px;
        height: 90rem;
    }
}



/* @media screen and (min-width: 2450px) {
    .vertical__partition {
        width: 6px;
        height: 97rem;
    }
} */



@media screen and (max-width: 1680px) {
    .app__partnership_partner {
        padding-right: 2rem;
    }
    
    .app__partnership_learn {
        padding-left: 2rem;
    }

    .app__partnership_partner-subheading {
        width: 100%;
        margin: 1.5rem 0 0rem;
        text-align: center;
    }

    .vertical__partition {
        height: 58rem;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1500px) {
    .app__partnership_partner-heading,
    .app__partnership_learn-heading {
        margin-bottom: 3rem;
    }

    .app__partnership_partner-subheading {
        margin: 3rem 0 0.5rem;
    }

    .vertical__partition {
        height: 58rem;
    }
}



@media screen and (max-width: 1380px) {
    .app__partnership_partner-subheading {
        margin: 4rem 0 2rem;
    }

    .app__partnership_partner-description {
        margin-bottom: 5rem;
    }

    .app__partnership_partner-subheading {
        margin: 3rem 0 1rem;
    }

    .vertical__partition {
        height: 58rem;
    }
}



@media screen and (max-width: 1280px) {
    .app__partnership_partner-description {
        margin-bottom: 3rem;
    }

    .app__partnership_partner-subheading {
        margin: 3rem 0 2rem;
    }

    .vertical__partition {
        height: 60rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__partnership_partner-heading,
    .app__partnership_learn-heading {
        margin-bottom: 2rem;
    }

    .app__partnership_partner-subheading {
        margin: 2rem 0 0rem;
    }

    .app__partnership_partner {
        padding-right: 0rem;
    }
    
    .app__partnership_learn {
        padding-left: 0rem;
    }

    .app__partnership_partner-description {
        margin-bottom: 3rem;
    }

    .vertical__partition {
        width: 3px;
        height: 50rem;
    }
}



@media screen and (max-width: 850px) {
    .app__partnership_partner-subheading {
        margin: 3rem 0 1.3rem;
    }

    .benefit__illustrationcards .app__illustrationcard:first-child {
        width: 100%;
    }

    .vertical__partition {
        width: 3px;
        height: 73rem;
    }
}



@media screen and (max-width: 650px) {
    .app__partnership {  
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .app__partnership_partner,
    .app__partnership_learn {
        width: 100%;
    }
    
    .app__partnership_partner-heading,
    .app__partnership_learn-heading {
        width: 100%;
        margin-bottom: 3rem;
        text-align: left;
    }

    .app__partnership_partner-description {
        margin-bottom: 2rem;
    }
    
    .app__partnership_partner-subheading {
        width: 100%;
        margin: 3rem 0 1rem;
        text-align: center;
    }

    .app__partnership_partner-heading {
        margin-bottom: 2rem;
    }

    .app__partnership_learn-heading {
        margin: 3rem 0;
    }

    .vertical__partition {
        display: none;
    }

    .benefit__illustrationcards .app__illustrationcard:first-child {
        width: 30%;
    }

    .app__partnership_learn-learningcards .app__learningcard:last-child {
        margin-bottom: 0;
    }
}



@media screen and (max-width: 500px) {  
    .app__partnership_partner-description {
        margin-bottom: 1rem;
    }
    
    .app__partnership_partner-subheading {
        margin: 2rem 0 1rem;
    }

    .app__partnership_partner-heading {
        margin-bottom: 1rem;
    }

    .app__partnership_learn-heading {
        margin: 1rem 0 2rem;
    }

    .vertical__partition {
        display: none;
    }
}



@media screen and (max-width: 450px) {
    .app__partnership_learn-heading {
        margin: 2rem 0 2rem;
    }

    .app__partnership_partner-heading {
        margin: 0 0 2rem;
    }

    .app__partnership_partner-subheading {
        margin: 3rem 0 1rem;
    }
}



@media screen and (max-width: 400px) {
    .app__partnership_learn-heading {
        margin: 2rem 0 2rem;
    }

    .app__partnership_partner-heading {
        margin: 0 0 1rem;
    }

    .app__partnership_partner-subheading {
        margin: 2rem 0 1rem;
    }
}