.app__coursecard-container {
    width: 32%;
    cursor: pointer;
}

.app__coursecard {
    width: 100%;
    /* min-height: 30rem; */
    margin-bottom: 2rem;
    text-align: left;
    background-color: var(--color-white);
    border-radius: 6px;
    transition: 0.2s;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.app__coursecard:hover {
    transform: scale(1.02);
    transition: 0.5s;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.4);
}

.app__coursecard-illustration {
    width: 32%;
    padding: 1rem 0;
}

.mobius__illustration {
    width: 30%;
}

.app__coursecard-title {
    width: 100%;
    height: 5rem;
    padding: 1rem 2rem;
    color: var(--color-black);
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.005em;
    box-shadow: 0px 0px 12px rgba(36, 79, 105, 0.2);
    align-items: flex-start;
}

.app__coursecard-footer {
    width: 100%;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--color-lightblue);

    flex-direction: row;
    justify-content: space-between;
}

.app__coursecard-footer_logo {
    width: 22px;
    margin-right: 0.5rem;
}

.app__coursecard-footer_logo img {
    width: 100%;
    height: 100%;
}

.mobius__monogram {
    width: 40px;
}

.app__coursecard-footer_name {
    width: auto;
    font-weight: 500;
    font-family: var(--font-main);
    letter-spacing: 0.03em;
    line-height: 0;
    color: var(--color-black);
}

.app__coursecard-footer .app__learningcard_navigate {
    width: auto;
    transform: scale(0.85) translateX(1rem);
    margin: 0;
    color: var(--color-black);
    line-height: 0 !important;
}

.app__coursecard-footer .app__learningcard_navigate .dropdown_arrow {
    color: var(--color-black);
    margin: 0;
    margin-left: 0.1rem;
}

.app__coursecard-footer .app__learningcard_navigate:hover {
    color: var(--color-royalblue);
}

.app__coursecard-footer .app__learningcard_navigate:hover .dropdown_arrow {
    margin-left: 0.1rem;
    color: var(--color-royalblue);
}



@media screen and (min-width: 2000px) {
    .app__coursecard {
        margin-bottom: 3rem;
    }

    .app__coursecard-title {
        height: 8rem;
        padding: 2rem 2rem;
        line-height: 36px;
    }
    
    .app__coursecard-footer_name {
        font-size: 22px;
    }
}



@media screen and (min-width: 1351px) and (max-width: 1680px) {
    .app__coursecard-illustration {
        width: 35%;
    }

    .app__coursecard-title {
        height: 5rem;
    }

    .app__coursecard-footer_logo {
        width: 25px;
    }

    .mobius__monogram {
        width: 40px;
    }
}



@media screen and (max-width: 1440px) {
    .mobius__illustration {
        width: 30%;
    }
    
    .app__coursecard-title {
        height: 5.5rem;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1367px) {
    .app__coursecard {
        margin-bottom: 1.5rem;
    }
    .app__coursecard-title {
        height: 5rem;
    }

    .app__coursecard-footer_logo {
        width: 20px;
    }

    .mobius__monogram {
        width: 34px;
    }
}



@media screen and (max-width: 1150px) {
    .app__coursecard-container {
        width: 48%;
    }

    .app__coursecard-illustration {
        width: 40%;
    }

    .mobius__illustration {
        width: 38%;
    }

    .app__coursecard-title {
        height: 5.5rem;
    }

    .app__coursecard-footer_logo {
        width: 22px;
    }

    .mobius__monogram {
        width: 36px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(1) translateX(0);
    }

    .app__coursecard-footer .app__learningcard_navigate .dropdown_arrow {
        font-size: 1.4rem !important;
    }
}



@media screen and (max-width: 850px) {
    .app__coursecard {
        margin-bottom: 2rem;
    }
    
    .app__coursecard-title {
        height: 7rem;
        padding: 1rem;
        font-size: 20px;
        line-height: 30px;
    }

    .app__coursecard-footer {
        padding: 0.8rem 1rem;
    }

    .app__coursecard-footer_logo {
        width: 22px;
    }

    .mobius__monogram {
        width: 36px;
    }

    .app__coursecard-footer_name {
        font-size: 16px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(1) translateX(0.8rem);
    }
}



@media screen and (max-width: 780px) {
    .app__coursecard-title {
        height: 6rem;
        font-size: 18px;
        line-height: 24px;
    }
}



@media screen and (max-width: 650px) {
    .app__coursecard {
        margin-bottom: 1.5rem;
    }

    .app__coursecard:hover {
        transform: scale(1.01);
    }
    
    .app__coursecard-title {
        font-size: 16px;
        line-height: 22px;
    }

    .app__coursecard-footer_name {
        font-size: 14px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(0.88) translateX(1rem);
    }
}



@media screen and (max-width: 580px) {
    .app__coursecard {
        margin-bottom: 1.5rem;
    }

    .app__coursecard-illustration {
        width: 40%;
    }

    .app__coursecard-title {
        height: 6rem;
        padding: 1rem;
        font-size: 16px;
        line-height: 20px;
    }

    .app__coursecard-footer {
        padding: 0.5rem 1rem;
    }

    .app__coursecard-footer_logo {
        width: 10%;
    }

    .mobius__monogram {
        width: 20%;
    }

    .app__coursecard-footer_name {
        font-size: 12px;
    }

    .app__coursecard-footer_logo {
        width: 22px;
    }

    .mobius__monogram {
        width: 36px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(0.88) translateX(0.8rem);
    }
}



@media screen and (max-width: 480px) {
    .app__coursecard-illustration {
        width: 50%;
    }

    .app__coursecard-title {
        height: 7rem;
    }

    .app__coursecard-footer_logo {
        width: 20px;
    }

    .mobius__monogram {
        width: 32px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(0.8) translateX(1rem);
    }
}



@media screen and (max-width: 450px) {
    .app__coursecard-container {
        width: 100%;
    }

    .app__coursecard-illustration {
        width: 50%;
    }

    .app__coursecard-title {
        height: 5rem;
        padding: 1rem;
        font-size: 16px;
        line-height: 20px;
    }

    .app__coursecard-footer_logo {
        width: 20px;
    }

    .mobius__monogram {
        width: 30px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(0.9) translateX(0);
    }
}



@media screen and (max-width: 400px) {
    .app__coursecard-illustration {
        width: 40%;
    }

    .app__coursecard-title {
        height: 4rem;
        padding: 1rem 1rem;
        font-size: 10px;
        line-height: 14px;
    }

    .app__coursecard-footer_logo {
        width: 20px;
    }

    .mobius__monogram {
        width: 32px;
    }

    .app__coursecard-footer_name {
        font-size: 8px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(0.8) translateX(0);
    }
}



@media screen and (max-width: 360px) {
    .app__coursecard-title {
        padding: 1rem;
        font-size: 14px;
        line-height: 18px;
    }

    .app__coursecard-footer_logo {
        width: 20px;
    }

    .mobius__monogram {
        width: 32px;
    }

    .app__coursecard-footer_name {
        font-size: 12px;
    }

    .app__coursecard-footer .app__learningcard_navigate {
        transform: scale(1) translateX(0);
    }
}