.app__registeredcoursecard {
    width: 48%;
    height: 12rem;
    margin-bottom: 4rem;
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 0.669644px 1.33929px 8.03573px rgba(36, 79, 105, 0.2);

    flex-direction: row;
    justify-content: space-between;
}

.app__registeredcoursecard-coursedetails {
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.app__registeredcoursecard-coursedetails-image {
    height: 12rem;
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2.67858px;
}

.app__registeredcoursecard-coursedetails-image img {
    height: 100%;
}

.app__registeredcoursecard-coursedetails-title {
    width: 60%;
    padding: 2.3rem 2rem;
    font-weight: 500;
}

.vertical__bar {
    width: 3px;
    height: 7.6rem;
    background-color: var(--color-lightblue);
}

.app__registeredcoursecard-navigation {
    width: 30%;
    height: 100%;
    padding: 2.5rem 3rem 2.2rem 0;

    flex-direction: column;
    justify-content: space-between;
}

.app__registeredcoursecard-navigation-learn,
.app__registeredcoursecard-navigation-view {
    width: 100%;
    border-radius: 4px;
    display: block;
    transition: 0.1s;
}

.app__registeredcoursecard-navigation-learn button,
.app__registeredcoursecard-navigation-view button {
    width: 100%;
    padding: 0.8rem 0;
    color: var(--color-black);
    background-color: var(--color-blue);
    color: var(--color-white);
    outline: none;
    border: none;
    border: 2px solid var(--color-blue);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s;
}

.app__registeredcoursecard-navigation-view button {
    background-color: var(--color-white);
    color: var(--color-blue);
}

.app__registeredcoursecard-navigation-learn:hover,
.app__registeredcoursecard-navigation-view:hover {
    transform: scale(1.005);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.app__registeredcoursecard-navigation-learn:active,
.app__registeredcoursecard-navigation-view:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}



@media screen and (min-width: 2000px) {
    .app__registeredcoursecard {
        height: 15rem;
    }
        
    .app__registeredcoursecard-coursedetails-image {
        height: 15rem;
        box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2.67858px;
    }

    .app__registeredcoursecard-coursedetails-title {
        padding: 3rem 2rem;
    }

    .vertical__bar {
        width: 3px;
        height: 8rem;
    }

    .app__registeredcoursecard-navigation {
        padding: 3.5rem 4rem 3.5rem 0;
    }

    .app__registeredcoursecard-navigation-learn button,
    .app__registeredcoursecard-navigation-view button {
        padding: 1rem 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 1680px) {
    .app__registeredcoursecard-coursedetails-title {
        padding: 3rem 1rem;
    }

    .vertical__bar {
        width: 3px;
        height: 7rem;
    }

    .app__registeredcoursecard-navigation {
        width: 25%;
        padding: 2.5rem 1rem 2.2rem 0;
    }
}



@media screen and (max-width: 1440px) {
    .app__registeredcoursecard {
        height: 12rem;
    }

    .app__registeredcoursecard-coursedetails-image {
        height: 12rem;
        box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2.67858px;
    }

    .app__registeredcoursecard-coursedetails-title {
        width: 70%;
        padding: 2.3rem 0.5rem;
    }

    .app__registeredcoursecard-navigation {
        width: 25%;
        padding: 2.5rem 1rem 2.5rem 0;
    }
}



@media screen and (max-width: 1380px) {
    .app__registeredcoursecard {
        height: 12rem;
    }

    .app__registeredcoursecard-coursedetails-image {
        height: 12rem;
        box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2.67858px;
    }

    .app__registeredcoursecard-coursedetails-title {
        padding: 2.3rem 0 2.3rem 1rem;
    }

    .app__registeredcoursecard-navigation {
        width: 30%;
        padding: 2.5rem 1rem 2.5rem 0;
    }
}



@media screen and (max-width: 1150px) {
    .app__registeredcoursecard {
        width: 100%;
    }

    .app__registeredcoursecard-navigation {
        width: 25%;
        padding: 2.5rem 4rem 2.5rem 0;
    }

    .app__registeredcoursecard-navigation-learn button,
    .app__registeredcoursecard-navigation-view button {
        font-size: 16px;
    }
}



@media screen and (max-width: 1000px) {
    .app__registeredcoursecard-navigation {
        width: 30%;
    }
}



@media screen and (max-width: 850px) {
    .app__registeredcoursecard-navigation {
        width: 30%;
        padding: 2.5rem 2rem 2.5rem 0;
    }

    .app__registeredcoursecard-navigation-learn button,
    .app__registeredcoursecard-navigation-view button {
        font-size: 14px;
    }
}



@media screen and (max-width: 650px) {
    .app__registeredcoursecard {
        height: 10rem;
    }

    .app__registeredcoursecard-coursedetails-image {
        height: 10rem;
        box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2.67858px;
    }

    .app__registeredcoursecard-coursedetails-title {
        padding: 2rem 0 2rem 1rem;
    }

    .vertical__bar {
        width: 3px;
        height: 6rem;
    }

    .app__registeredcoursecard-navigation {
        width: 30%;
        padding: 2rem 1rem 2rem 0;
    }
}



@media screen and (max-width: 600px) {
    .app__registeredcoursecard {
        height: fit-content;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .app__registeredcoursecard-coursedetails {
        width: 100%;
        justify-content: space-evenly;
    }

    .app__registeredcoursecard-coursedetails-image {
        width: 30%;
        height: auto;
        box-shadow: none;
        border-radius: 2.67858px;
    }

    .app__registeredcoursecard-coursedetails-image img {
        width: 10rem;
        height: auto;
    }

    .app__registeredcoursecard-coursedetails-title {
        width: 70%;
        padding: 1.6rem 0 1.6rem 1rem;
    }

    .app__registeredcoursecard-navigation {
        width: 100%;
        height: auto;
        margin: 1rem 0 2rem;
        padding: 0;
    
        flex-direction: row;
        justify-content: flex-start;
    }

    .app__registeredcoursecard-navigation-learn,
    .app__registeredcoursecard-navigation-view {
        width: 30%;
    }

    .app__registeredcoursecard-navigation-learn {
        margin: 0 1.8rem;
    }
}



@media screen and (max-width: 480px) {
    .app__registeredcoursecard-navigation-learn,
    .app__registeredcoursecard-navigation-view {
        width: 35%;
    }

    .app__registeredcoursecard-navigation-learn {
        margin: 0 1rem;
    }
}



@media screen and (max-width: 420px) {
    .app__registeredcoursecard-coursedetails-image img {
        width: 8rem;
        height: auto;
    }

    .app__registeredcoursecard-coursedetails-title {
        width: 70%;
        padding: 1.2rem 0 1.2rem 1rem;
    }

    .app__registeredcoursecard-navigation-learn,
    .app__registeredcoursecard-navigation-view {
        width: 42%;
    }

    .app__registeredcoursecard-navigation-learn {
        margin: 0 1rem;
    }
}



@media screen and (max-width: 400px) {
    .app__registeredcoursecard-coursedetails-image img {
        width: 6rem;
        height: auto;
    }

    .app__registeredcoursecard-navigation-learn,
    .app__registeredcoursecard-navigation-view {
        width: 42%;
    }

    .app__registeredcoursecard-navigation-learn {
        margin: 0 1rem;
    }

    .app__registeredcoursecard-navigation-learn button,
    .app__registeredcoursecard-navigation-view button {
        font-size: 12px;
    }
}
