.formContainer {
    height: auto !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: var(--font-main);
}

.formContainer form {
    margin-top: 1rem;
    width: 40%;
}

.formContainer .form__heading {
    width: 40%;
    margin: 3rem 0 1rem;
    font-weight: 500;
    line-height: 0;
}

.name {
    margin-bottom: 0.6rem;
}

.name input,
.otherInfo div label {
    margin-top: 0.2rem;
}

.otherInfo div {
    margin-bottom: 0.4rem;
}

.name input,
.otherInfo div input {
    margin-top: 0.2rem;
    height: 32px;
    padding: 0.2rem 0.5rem;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.red {
    transition: 0.1s;
    border: 2px solid rgba(255, 0, 0, 0.603) !important;
}

.red1 {
    transition: 0.1s;
    border: 2px solid rgba(255, 0, 0, 0.603) !important;
    height: 32px;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    cursor: pointer;
}

.select {
    height: 32px;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    cursor: pointer;
}

.nameContainer {
    display: flex;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
    /* width: 48%; */
}

.otherInfo div {
    display: flex;
    flex-direction: column;
    margin-top: 0.4rem;
}


.btnContainer {
    display: flex;
    justify-content: center;
    margin: 2rem 0 3rem !important;
}

.btnContainer button {
    cursor: pointer;
    border-radius: 4px;
    font-family: "Poppins";
    color: white;
    background-color: #1694d1;
    transition: 0.5s;
    width: 120px;
    height: 2rem;
    padding: 0;
    border: 0;
    outline: 0;
}

.btnContainer button:hover {
    background-color: #106f9c;
    transition: 0.5s;
}

/* for password eye icon */
.app_input_pass {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    column-gap: 3px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.app_input_pass input {
    width: 97%;
    height: 28px;
    border: none !important;
    box-shadow: none !important;
}

.app_input_pass input[type=text]:focus,
.app_input_pass input[type=password]:focus,
.app_input_pass input[type=text]:focus-visible,
.app_input_pass input[type=password]:focus-visible {
    border: none !important
}

.app_input_pass_eye {
    cursor: pointer;
    width: 2.7%;
    padding: 0.2rem 0.5rem;

}


@media screen and (width < 480px) {
    .formContainer .form__heading {
        width: 90%;
    }

    .formContainer form {
        width: 90%;
    }

    .name input,
    .otherInfo div input {
        height: 20px;
    }
}

@media screen and (width < 980px) {
    .formContainer .form__heading {
        width: 80%;
    }

    .formContainer form {
        width: 80%;
    }

    .name input,
    .otherInfo div input {
        height: 34px;
    }
}