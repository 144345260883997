.app__company {
    width: 100%;
    background-color: var(--color-white);
    position: relative;
    z-index: 1;
}

.app__snaplogic {
    background-color: var(--color-royalblue);
}

.app__mobius {
    background-color: transparent;
    background-image: linear-gradient(270deg, rgba(147, 18, 169, 1), rgb(0, 74, 128));
}

.company__monogram_top {
    width: 30rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.company__monogram_bottom {
    width: 15rem;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.mobius__monogram_top {
    width: 70rem;
    opacity: 0.6;

    position: absolute;
    top: -30%;
    right: -25%;
    z-index: -1;
}

.mobius__monogram_bottom {
    width: 50rem;
    opacity: 0.5;

    position: absolute;
    bottom: -30%;
    left: -22%;
    z-index: -1;
}

.app__company-heading {
    margin-bottom: 1rem;
    color: var(--color-white);
    font-weight: 600;
}

.app__company-logo {
    width: 12%;
    margin: 0 0 1rem;
}

.app__company-description {
    width: 70%;
    color: var(--color-white);
}

.app__company-button {
    width: 15%;
    margin: 3rem 80% 0;
    display: block;
    transition: 0.1s;
}

.app__company-button button {
    width: 100%;
    padding: 1.3rem 0;
    color: var(--color-royalblue);
    background-color: var(--color-white);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s;
}

.app__company-button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
}

.app__company-button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.app__company-button button .dropdown_arrow {
    margin-left: 0.5rem;
    color: var(--color-royalblue);
}



@media screen and (min-width: 2000px) {
    .mobius__monogram_top {
        width: 100rem;
    
        top: -40%;
        right: -28%;
    }
    
    .mobius__monogram_bottom {
        width: 70rem;
    
        bottom: -32%;
        left: -25%;
    }

    .app__company-logo {
        width: 11%;
    }

    .app__company-button {
        width: 12%;
    }
    
    .app__company-button button {
        width: 100%;
        padding: 1.3rem 0;
        font-size: 22px;
    }
}



@media screen and (max-width: 1680px) {
    .mobius__monogram_top {
        width: 70rem;

        top: -30%;
        right: -30%;
    }
    
    .mobius__monogram_bottom {
        width: 50rem;

        bottom: -30%;
        left: -25%;
    }
}



@media screen and (max-width: 1440px) {
    .app__company-logo {
        width: 14%;
        margin: 0 0 0;
    }

    .mobius__monogram_top {
        width: 60rem;

        top: -30%;
        right: -30%;
    }
    
    .mobius__monogram_bottom {
        width: 40rem;

        bottom: -25%;
        left: -25%;
    }
}



@media screen and (max-width: 1380px) {
    .app__company-logo {
        width: 15%;
    }

    .large__button {
        width: 18%;
    }
}



@media screen and (max-width: 1280px) {
    .app__company-logo {
        width: 16%;
    }

    .mobius__monogram_top {
        width: 60rem;

        top: -30%;
        right: -35%;
    }
    
    .mobius__monogram_bottom {
        width: 40rem;

        bottom: -25%;
        left: -28%;
    }
}



@media screen and (max-width: 1150px) {
    .app__company-logo {
        width: 23%;
    }

    .mobius__monogram_top {
        width: 50rem;

        top: -20%;
        right: -35%;
    }
    
    .mobius__monogram_bottom {
        width: 40rem;

        bottom: -18%;
        left: -32%;
    }

    .app__company-button {
        width: 18%;
        margin: 3rem 75% 0;
        padding: 0.8rem 0;
        font-size: 16px;
        line-height: 20px;
    }
    
    .app__company-button:hover {
        box-shadow: none;
    }

    .large__button {
        width: 23%;
        margin: 3rem 72% 0;
    }
}



@media screen and (max-width: 1000px) {
    .app__company-button {
        height: fit-content;
        padding: -0.2rem 0;
    }
}



@media screen and (max-width: 850px) {
    .app__company-logo {
        width: 30%;
    }

    .mobius__monogram_top {
        width: 40rem;

        top: -18%;
        right: -35%;
    }
    
    .mobius__monogram_bottom {
        width: 30rem;

        bottom: -18%;
        left: -32%;
    }

    .app__company-button {
        width: 20%;
        margin: 3rem 73% 0;
    }

    .app__company-button button {
        padding: 1rem 0;
        font-size: 14px;
    }

    .large__button {
        width: 26%;
        margin: 3rem 70% 0;
    }
}



@media screen and (max-width: 650px) {
    .app__company-logo {
        width: 32%;
    }

    .mobius__monogram_top {
        width: 40rem;

        top: -18%;
        right: -50%;
    }
    
    .mobius__monogram_bottom {
        width: 30rem;

        bottom: -18%;
        left: -40%;
    }

    .app__company-description {
        width: 90%;
    }

    .app__company-button {
        width: 22%;
        margin: 3rem 70% 0;
    }

    .app__company-button button {
        padding: 1rem 0;
        font-size: 14px;
    }

    .large__button {
        width: 30%;
        margin: 3rem 65% 0;
    }
}



@media screen and (max-width: 580px) {
    .mobius__monogram_top {
        width: 35rem;

        top: -18%;
        right: -50%;
    }
    
    .mobius__monogram_bottom {
        width: 28rem;

        bottom: -18%;
        left: -45%;
    }
}



@media screen and (max-width: 480px) {
    .app__company-logo {
        width: 40%;
    }

    .app__company-description {
        width: 100%;
    }

    .app__company-button {
        width: 28%;
        margin: 3rem 65% 0;
    }

    .app__company-button button {
        padding: 1rem 0;
        font-size: 14px;
    }

    .large__button {
        width: 36%;
        margin: 3rem 60% 0;
    }
}



@media screen and (max-width: 450px) {
    .app__company-logo {
        width: 45%;
    }

    .app__company-button {
        width: 36%;
        margin: 3rem 58% 0;
    }

    .app__company-button button {
        padding: 1rem 0;
        font-size: 14px;
    }

    .large__button {
        width: 48%;
        margin: 3rem 48% 0;
    }
}



@media screen and (max-width: 360px) {
    .app__company-logo {
        width: 48%;
    }

    .app__company-button {
        width: 36%;
        margin: 3rem 58% 0;
    }

    .app__company-button button {
        padding: 1rem 0;
        font-size: 12px;
    }

    .large__button {
        width: 48%;
        margin: 3rem 48% 0;
    }
}
