img {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}

a {
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.hidden {
    overflow-y: hidden;
}

.scroll {
    overflow-y: scroll;
}

.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section__padding {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
}

.app__landing_bg {
    width: 100%;
    background: url('./assets/images/hero_image.svg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    /* background-attachment: fixed; */
}

.p__navigation {
    font-family: var(--font-main);
    color: var(--color-blue);
    font-weight: 500;
    text-transform: capitalize;
    line-height: 30px;
    font-size: 22px;
}

.p__navigation_small {
    font-family: var(--font-main);
    color: var(--color-blue);
    font-weight: 500;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 20px;
}

.p__heading {
    font-family: var(--font-main);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 40px;
    font-size: 35px;
}

.p__subheading {
    font-family: var(--font-main);
    font-weight: 500;
    text-transform: capitalize;
    line-height: 30px;
    font-size: 26px;
}

.p__paragraph {
    font-family: var(--font-main);
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
}

.p__large {
    font-family: var(--font-main);
    font-size: 50px;
    font-weight: 600;
    line-height: 80px;
}

.p__semibold {
    font-family: var(--font-main);
    color: var(--color-blue);
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 30px;
    font-size: 30px;
}

.nav__button {
    padding: 0.8rem 1.8rem;
    background-color: var(--color-darkblue);
    border: none;
    border-radius: 4px;
    outline: none;
    font-family: var(--font-main);
    font-size: 22px;
    font-weight: 500;
    color: var(--color-white);
    transition: 0.1s;
    cursor: pointer;
}

.nav__button:hover {
    background-color: var(--color-blue);
}

.nav__button:active {
    transform: scale(1.02);
    transition: 0.3s;
}

.toast {
    min-width: fit-content;
}



@media screen and (min-width: 2000px) {
    .section__padding {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .p__navigation {
        line-height: 40px;
        font-size: 28px;
    }

    .p__navigation_small {
        line-height: 50px;
        font-size: 26px;
    }

    .p__heading {
        line-height: 50px;
        font-size: 44px;
    }

    .p__subheading {
        line-height: 60px;
        font-size: 36px;
    }

    .p__paragraph {
        line-height: 34px;
        font-size: 26px;
    }

    .p__large {
        font-size: 72px;
        font-weight: 700;
        line-height: 100px;
        letter-spacing: 0.04em;
    }
    
    .p__semibold {
        line-height: 60px;
        font-size: 45px;
    }

    .nav__button {
        font-size: 28px;
    }
}



@media screen and (min-width: 1200px) and (max-width: 1680px) {
    .p__subheading {
        line-height: 30px;
        font-size: 24px;
    }
}



@media screen and (min-width: 1151px) and (max-width: 1500px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .p__heading {
        line-height: 40px;
        font-size: 30px;
    }

    .p__subheading {
        line-height: 30px;
        font-size: 24px;
    }

    .p__navigation {
        line-height: 30px;
        font-size: 22px;
    }

    .nav__button {
        font-size: 22px;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1440px) {
    .p__large {
        font-size: 48px;
        line-height: 60px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }
}



@media screen and (max-width: 1150px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .p__heading {
        line-height: 40px;
        font-size: 26px;
    }

    .p__subheading {
        line-height: 26px;
        font-size: 22px;
    }

    .p__paragraph {
        line-height: 22px;
        font-size: 18px;
    }

    .p__large {
        font-size: 52px;
        line-height: 74px;
        font-weight: 700;
        letter-spacing: 0.04em;
    }
    
    .p__semibold {
        line-height: 40px;
        font-size: 30px;
    }

    .p__navigation {
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
    }

    .p__navigation_small {
        font-weight: 500;
        line-height: 20px;
        font-size: 16px;
    }

    .nav__button {
        padding: 0.8rem 1rem;
        font-size: 18px;
        font-weight: 600;
    }
}



@media screen and (max-width: 1000px) {
    .p__large {
        font-size: 48px;
        line-height: 60px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }
}



@media screen and (max-width: 850px) {
    .p__heading {
        line-height: 30px;
        font-size: 26px;
    }

    .p__subheading {
        line-height: 28px;
        font-size: 22px;
    }

    .p__paragraph {
        line-height: 20px;
        font-size: 14px;
    }

    .p__large {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.03em;
    }

    .p__semibold {
        line-height: 30px;
        font-size: 25px;
    }

    .p__navigation {
        line-height: 30px;
        font-size: 24px;
    }

    .p__navigation_small {
        line-height: 30px;
        font-size: 22px;
    }
}



@media screen and (max-width: 650px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .p__heading {
        line-height: 40px;
        font-size: 28px;
    }

    .p__subheading {
        line-height: 28px;
        font-size: 22px;
    }

    .p__paragraph {
        line-height: 22px;
        font-size: 16px;
    }

    .p__navigation {
        line-height: 30px;
        font-size: 22px;
    }

    .p__navigation_small {
        line-height: 30px;
        font-size: 20px;
    }
}



@media screen and (max-width: 500px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}



@media screen and (min-width: 450px) and (max-width: 480px) {
    .p__subheading {
        line-height: 28px;
        font-size: 22px;
    }
}



@media screen and (max-width: 450px) {
    .section__padding {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .p__heading {
        line-height: 35px;
        font-size: 24px;
    }

    .p__subheading {
        line-height: 28px;
        font-size: 22px;
    }

    .p__large {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.04em;
    }

    .p__paragraph {
        line-height: 22px;
        font-size: 16px;
    }

    .p__navigation {
        line-height: 30px;
        font-size: 20px;
    }

    .p__navigation_small {
        line-height: 30px;
        font-size: 18px;
    }
}



@media screen and (max-width: 400px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .p__large {
        font-size: 22px;
        line-height: 25px;
    }

    .p__subheading {
        line-height: 22px;
        font-size: 18px;
    }

    .p__paragraph {
        line-height: 18px;
        font-size: 14px;
    }
}



@media screen and (max-width: 361px) {
    .p__navigation {
        line-height: 20px;
        font-size: 18px !important;
    }

    .p__navigation_small {
        line-height: 20px;
        font-size: 16px;
    }
}



@media screen and (max-width: 350px) {
    .section__padding {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .p__heading {
        line-height: 28px;
        font-size: 22px;
    }

    .p__subheading {
        line-height: 20px;
        font-size: 18px;
    }

    .p__paragraph {
        line-height: 18px;
        font-size: 14px;
    }
}