.app__infocard {
    width: 100%;

    flex-direction: row;
    align-items: flex-start;
}

.app__infocard-info {
    width: 45%;
    height: 40rem;
    padding: 5rem 6rem 5rem 5rem;
    color: var(--color-white);
    background-color: var(--color-blue);
}

.app__infocard-info-title {
    width: 80%;
    margin-bottom: 2rem;
}

.app__infocard-info-description {
    width: 80%;
    list-style-type: none;
}

.app__infocard-info-description li {
    margin-bottom: 0.6rem;
    font-weight: 500;
}

.app__infocard-info-description li::before {
    width: 8%;
    margin-right: 1rem;
    content: url('../../assets/icon/checkmark_icon.svg');
}

.app__infocard-info-register {
    margin: 3rem 0 0;
    display: block;
}

.app__infocard-info-register button {
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-white);
    background-color: var(--color-yellow);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    cursor: pointer;
}

.app__infocard-info-register button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.app__infocard-info-register button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.app__infocard-image {
    width: 55%;
    height: 40rem;
    overflow: hidden;
}

.app__infocard-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media screen and (min-width: 2000px) {
    .app__infocard-info {
        height: 50rem;
        padding: 7rem 6rem 5rem 5rem;
    }

    .app__infocard-info-register {
        margin-top: 5rem;
    }

    .app__infocard-info-register button {
        padding: 1rem 2rem;
        font-size: 22px;
    } 
    
    .app__infocard-image {
        height: 50rem;
    }
}



@media screen and (min-width: 1400px) and (max-width: 1700px) {
    .app__infocard-info {
        padding: 5rem 5rem 5rem 5rem;
    }

    .app__infocard-info-register button {
        padding: 0.8rem 1.5rem;
        font-size: 18px;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1440px) {
    .app__infocard-info {
        height: 40rem;
        padding: 5rem 0 5rem 3rem;
    }

    .app__infocard-info-register button {
        padding: 0.8rem 1.5rem;
        font-size: 18px;
    }

    .app__infocard-image {
        height: 40rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__infocard {
        flex-direction: column;
    }

    .app__infocard-info {
        width: 100%;
        height: fit-content;
        padding: 3rem 4rem 3rem 5rem;
    }

    .app__infocard-image {
        width: 100%;
        height: 25rem;
    }

    .app__infocard-info-register button {
        margin-top: 0rem;
        padding: 1rem 1.5rem;
        font-size: 18px;
    }
}



@media screen and (max-width: 850px) {
    .app__infocard-info {
        padding: 4rem 3rem 3rem 3rem;
    }

    .app__infocard-info-register button {
        padding: 1rem 1.5rem;
        font-size: 16px;
    }

    .app__infocard-image {
        height: 20rem;
    }
}



@media screen and (max-width: 650px) {
    .app__infocard-info {
        padding: 3rem 0 3rem 3rem;
    }

    .app__infocard-info-register {
        margin: 3rem 0 0;
    }

    .app__infocard-info-register button {
        padding: 1rem 1.5rem;
        font-size: 16px;
    }

    .app__infocard-info-register button:hover {
        transform: scale(1.01);
    }
}



@media screen and (max-width: 569px) {
    .app__infocard-info {
        padding: 3rem 0 3rem 2rem;
    }

    .app__infocard-info-register {
        margin-top: 2rem;
    }

    .app__infocard-info-register button {
        padding: 1rem 1.5rem;
        font-size: 16px;
    }
}



@media screen and (max-width: 480px) {
    .app__infocard-info {
        padding: 2rem 0rem 2rem 2rem;
    }

    .app__infocard-info-register button {
        font-size: 16px;
    }
}



@media screen and (max-width: 450px) {
    .app__infocard-info {
        padding: 3rem 0 1rem 2rem;
    }

    .app__infocard-info-title {
        margin-bottom: 1rem;
    }

    .app__infocard-info-description li {
        font-size: 12px;
        margin-bottom: 0.3rem;
    }

    .app__infocard-info-description li::before {
        width: 3%;
        margin-right: 0.6rem;
    }

    .app__infocard-info-register {
        margin-top: 1rem;
    }

    .app__infocard-info-register button {
        padding: 0.8rem 1.5rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 360px) {
    .app__infocard-info {
        padding: 2rem 0 2rem 1rem;
    }

    .app__infocard-info-register button {
        padding: 0.8rem 1.5rem;
        font-size: 14px;
    }

    .app__infocard-image {
        height: 16rem;
    }
}



@media screen and (max-width: 320px) {
    .app__infocard-info {
        padding: 2rem 1rem 1rem 1rem;
    }

    .app__infocard-info-register button {
        padding: 0.8rem 1.5rem;
        font-size: 12px;
    }
}