*{
    font-family: var(--font-main);
}
.switch {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: center;
    column-gap: 5rem;
    padding-right: 26vw;
}



.blueTxt {
    color: #1694d1;
    font-size: 1.4em;
    font-weight: 400;
}

.txt {
    color: black;
    font-size: 1.4em;
    font-weight: 400;
}

.tab {
    position: relative;
    margin-bottom: 2vh;
}

.tab img {
    position: absolute;
    top: 0;
    height: 19px;
    width: 34px;
}

.tabSignUp {
    transition: 0.1s;
    left: 31.5vw;
}

.tabSignIn {
    transition: 0.1s;
    left: 40.3vw;
}


hr {
    border-top: 1px solid #ccc;

}