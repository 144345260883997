.app__presence {
    width: 100%;
    background-color: var(--color-lightblue);
    overflow: hidden;
}

.app__presence_information {
    width: 50%;
}

.app__presence_information-title {
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
    color: var(--color-blue);
    font-size: 45px;
    font-weight: 600;
}

.app__presence_information-cities {
    margin-top: 3rem;
}

.app__presence_information-cities_listitem {
    width: 65%;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    border-radius: 4px;
    /* list-style-image: url(../../assets/illustrations/ListStyle.svg); */
    list-style-type: none;
    cursor: pointer;
}

.app__presence_information-cities_listitem::before {
    content: url(../../assets/illustrations/ListStyle.svg);
    margin-right: 2rem;
}

.app__presence_information-cities_listitem:hover {
    background-color: var(--color-white);
}

.app__presence_map {
    width: 35%;
}

/* .app__presence_map img {
    cursor: pointer;
} */



@media screen and (min-width: 2000px) {
    .app__presence_information-title {
        font-size: 55px;
    }

    .app__presence_information-cities_listitem {
        width: 60%;
    }

    .app__presence_map {
        width: 40%;
    }
}



@media screen and (max-width: 1680px) {
    .app__presence_information-title {
        font-size: 45px;
    }

    .app__presence_information-cities_listitem {
        width: 70%;
    }

    .app__presence_map {
        width: 38%;
    }
}



@media screen and (max-width: 1460px) {
    .app__presence_information-title {
        font-size: 40px;
    }

    .app__presence_information {
        width: 50%;
    }

    .app__presence_map {
        width: 45%;
    }

    .app__presence_information-cities_listitem {
        width: 80%;
    }
}



@media screen and (max-width: 1280px) {
    .app__presence_information-cities_listitem {
        width: 90%;
    }
}



@media screen and (max-width: 1150px) {
    .app__presence_information-title {
        font-size: 35px;
    }

    .app__presence_information-cities {
        margin-top: 1rem;
    }

    .app__presence_information-cities_listitem {
        width: 95%;
        margin-bottom: 0;
    }

    .app__presence_map {
        width: 50%;
    }
}



@media screen and (max-width: 950px) {
    .app__presence_information-cities_listitem {
        width: 60%;
    }
}



@media screen and (max-width: 850px) {
    .app__presence {
        flex-direction: column;
    }

    .app__presence_information-title {
        font-size: 36px;
    }

    .app__presence_information {
        width: 120%;
        margin-bottom: 0;
        transform: scale(0.8);
        order: 2;
    }

    .app__presence_map {
        width: 70%;
        margin-bottom: 1rem;
        order: 1;
    }

    .app__presence_information-cities_listitem {
        width: 60%;
    }
}



@media screen and (max-width: 680px) {
    .app__presence_information-title {
        font-size: 30px;
        margin-bottom: 3rem;
    }

    .app__presence_information {
        width: 160%;
        margin-bottom: -5rem;
        transform: scale(0.6);
    }

    .app__presence_map {
        /* width: 90%; */
        margin-bottom: -3rem;
    }

    .app__presence_information-cities_listitem {
        width: 60%;
    }
}



@media screen and (max-width: 580px) {
    .app__presence_information {
        width: 200%;
        margin-left: 7rem;
        margin-bottom: -5rem;
        transform: scale(0.6);
    }

    .app__presence_information-cities_listitem {
        width: 50%;
    }
}



@media screen and (max-width: 481px) {
    .app__presence_information {
        margin-left: 6rem;
    }
}



@media screen and (max-width: 450px) {
    .app__presence_information {
        margin-left: 5rem;
    }

    .app__presence_information-title {
        font-size: 30px;
    }

    .app__presence_information-cities_listitem {
        width: 70%;
    }

    .app__presence_map {
        width: 80%;
    }
}



@media screen and (max-width: 400px) {
    .app__presence_information-title {
        font-size: 26px;
    }
}



@media screen and (max-width: 360px) {
    .app__presence_information {
        margin-left: 2rem;
    }
}



@media screen and (max-width: 320px) {
    .app__presence_information {
        margin-left: 4rem;
    }
}