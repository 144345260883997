.app__blogcard{
    width: 30%;
    border-radius: 4px;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.2);
    transition: 0.2s;
    cursor: pointer;
}

.app__blogcard:hover {
    transform: scale(1.02);
    transition: 0.5s;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.3);
}

.app__blogcard_image {
    width: 100%;
    height: 18rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.app__blogcard_image img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.app__blogcard_description {
    height: 6rem;
    padding: 1rem;
    color: var(--color-black);

    align-items: flex-start;
}

.app__blogcard_date{
    width: 100%;
    height: 3rem;
    padding: 1rem;
    background-color: var(--color-lightblue);
    color: var(--color-black);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__blogcard_date .clock__icon {
    margin-right: 5px;
    color: var(--color-black);
    font-weight: 400;
    font-size: 18px;
}

.app__blogcard_date p {
    font-weight: 400;
    font-size: 16px;
    line-height: 0;
}



@media screen and (min-width: 2000px) {
    .app__blogcard_image {
        height: 22rem;
    }

    .app__blogcard_image img {
        height: 22rem;
    }

    .app__blogcard_description {
        height: 10rem;
    }

    .app__blogcard_date {
        height: 4rem;
    }

    .app__blogcard_date .clock__icon {
        margin-right: 8px;
        font-size: 24px;
    }
    
    .app__blogcard_date p {
        font-size: 20px;
    }
}



@media screen and (max-width: 1680px) {
    .app__blogcard_description {
        height: 7rem;
    }
}



@media screen and (max-width: 1440px) {
    .app__blogcard_image {
        height: 14rem;
    }

    .app__blogcard_image img {
        height: 14rem;
    }

    .app__blogcard_description {
        height: 8rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__blogcard {
        width: 28%;
    }

    .app__blogcard_image {
        height: 10rem;
    }

    .app__blogcard_image img {
        height: 10rem;
    }

    .app__blogcard_description {
        height: 9rem;
    }

    .app__blogcard_description p {
        font-size: 16px !important;
    }

    .app__blogcard_date .clock__icon {
        margin-right: 8px;
        font-size: 16px;
    }
    
    .app__blogcard_date p {
        font-size: 14px;
    }
}



@media screen and (max-width: 850px) {
    .app__blogcard {
        width: 45%;
    }

    .app__blogcard_description {
        height: 7rem;
    }

    .app__blogcard_date{
        height: 2rem;
    }
}



@media screen and (max-width: 650px) {
    .app__blogcard_description {
        height: 10rem;
    }
}



@media screen and (max-width: 580px) {
    .app__blogcard_image {
        height: 8rem;
    }

    .app__blogcard_image img {
        height: 8rem;
    }

    .app__blogcard_description {
        height: 12rem;
    }
}



@media screen and (max-width: 480px) {
    .app__blogcard {
        width: 94%;
    }

    .app__blogcard_image {
        height: 12rem;
    }

    .app__blogcard_image img {
        height: 12rem;
    }

    .app__blogcard_description {
        height: 6rem;
    }
}



@media screen and (max-width: 480px) {
    .app__blogcard {
        width: 92%;
    }

    .app__blogcard_description {
        height: 7rem;
    }
}



@media screen and (max-width: 360px) {
    .app__blogcard_description {
        height: 8rem;
    }
}
