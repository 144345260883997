.app__featurecard {
    width: 30%;
    padding: 1rem 0;
    flex-direction: row;
    justify-content: flex-start;
}

.app__featurecard-illustration {
    width: 5rem;
    margin-right: 2rem;
}

.app__featurecard-title {
    width: 100%;
    height: 100%;
    font-weight: 500;
}



@media screen and (min-width: 2000px) {
    .app__featurecard-illustration {
        width: 6rem;
    }
}



@media screen and (max-width: 1350px) {
    .app__featurecard {
        width: 33%;
    }
    
    .app__featurecard-illustration {
        width: 4rem;
        margin-right: 1rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__featurecard-title {
        width: 80%;
    }

    .app__featurecard-title span {
        font-size: 16px;
    }
}



@media screen and (max-width: 990px) {
    .app__featurecard-title span {
        font-size: 14px;
    }
}



@media screen and (max-width: 850px) {
    .app__featurecard {
        width: 33%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .app__featurecard-illustration {
        width: 3rem;
        margin: auto;
    }

    .app__featurecard-title {
        width: 80%;
        margin: auto;
        text-align: center;
    }
}



@media screen and (max-width: 650px) {
    .app__featurecard-title {
        font-size: 14px;
        line-height: 18px;
    }

    .app__featurecard-title span {
        font-size: 10px;
    }
}



@media screen and (max-width: 580px) {   
    .app__featurecard-illustration {
        width: 2.6rem;
    }

    .app__featurecard-title {
        font-size: 14px;
        line-height: 18px;
    }

    .app__featurecard-title span {
        font-size: 8px;
    }
}



@media screen and (max-width: 450px) {
    .app__featurecard {
        width: 50%;
    }
    
    .app__featurecard-illustration {
        width: 2.5rem;
    }

    .app__featurecard-title {
        width: 100%;
    }

    .app__featurecard-title span {
        font-size: 10px;
    }
}



@media screen and (max-width: 400px) {
    .app__featurecard {
        width: 50%;
    }
    
    .app__featurecard-illustration {
        width: 2rem;
    }

    .app__featurecard-title {
        width: 90%;
    }
}



@media screen and (max-width: 350px) {
    .app__featurecard-title span {
        font-size: 8px;
    }
}
