.app__allcourses {
    width: 100%;
    background-color: var((--color-white));
}

.app__allcourses-heading {
    margin: 0 0 3rem;
}

.app__allcourses-subheading {
    margin: 3rem 0 2rem;
    font-weight: 600;
}

.app__allcourses-coursecontainer {
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.app__allcourses-coursecontainer .carousel {
    margin-bottom: 5rem;
}

.app__allcourses-coursecontainer .carousel .slider-wrapper {
    height: 100%;
    padding: 0;
}

.app__allcourses-coursecontainer .carousel .carousel-slider {
    padding-bottom: 0rem;
    overflow: unset;
}

.app__allcourses-coursecontainer .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin: 1rem 2rem 1rem 0;
}

/* .app__allcourses-coursecontainer .carousel .slider-wrapper.axis-horizontal .slider .slide:first-child {
    margin-left: -14.5%;
} */

.app__allcourses-coursecontainer .carousel .control-arrow,
.app__allcourses-coursecontainer .carousel.carousel-slider .control-arrow {
    -webkit-transition:all .25s;
    -moz-transition:all .25s;
    -ms-transition:all .25s;
    -o-transition:all .25s;
    transition:all .25s;
    opacity: 0.6 !important;
    filter: alpha(opacity=60);
    background: transparent !important;

    /* display: none; */
}

.app__allcourses-coursecontainer .carousel .control-arrow:hover,
.app__allcourses-coursecontainer .carousel.carousel-slider .control-arrow:hover {
    opacity: 1 !important;
    filter: alpha(opacity=100);
}

.app__allcourses-coursecontainer .carousel .control-prev.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-black) !important;
    border-width: 8px 8px 0 0 !important;
    /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9); */
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    /* left: -10%; */
}

.app__allcourses-coursecontainer .carousel .control-next.control-arrow:before {
    width: 30px !important;
    height: 30px !important;
    content: '';
    border: solid var(--color-black) !important;
    border-width: 8px 8px 0 0 !important;
    /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9); */
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.app__allcourses-coursecontainer .carousel.carousel-slider .control-arrow {
    color: var(--color-blue) !important;
    font-size: 26px;
    padding: 20px !important;
}

.app__allcourses-coursecontainer .carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
}

.app__allcourses-coursecontainer .carousel .control-dots {
    bottom: -5rem;
}

.app__allcourses-coursecontainer .carousel .control-dots .dot {
    border-radius: 0 !important;
    width: 15px !important;
    height: 15px !important;
}



@media screen and (min-width: 2000px) {
    .app__allcourses-heading {
        margin: 0 0 4rem;
    }
}



@media screen and (max-width: 1500px) {
    .app__allcourses-heading {
        margin: 0 0 2rem;
    }
}



@media screen and (max-width: 850px) {
    .app__allcourses-coursecontainer {
        align-items: center;
    }
}



@media screen and (max-width: 650px) {
    .app__allcourses-heading {
        margin: 0 0 2rem;
    }
}