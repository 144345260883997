@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-main: 'Poppins', san-serif;

  --color-skyblue: #B1F1FF;
  --color-lightblue: #D6F0FF;
  --color-blue: #4ca8df;
  --color-darkblue: #118fd8;
  --color-royalblue: #043D87;
  --color-yellow: #FF9B2B;
  --color-lightyellow: #ffad4f;
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-grey: #28282880;
  --color-lightgrey: #959595;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--color-white);
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #cfcece;
}

body::-webkit-scrollbar-thumb {
  background: var(--color-blue);
  /* border-radius: 10px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes Restricted {
  0% {
    transform: rotateZ(0.4deg);
  }
  25% {
    transform: rotateZ(-0.8deg);
  }
  50% {
    transform: rotateZ(0.8deg);
  }
  75% {
    transform: rotateZ(-0.8deg);
  }
  100% {
    transform: rotateZ(0.8deg);
  }
}



@media screen and (max-width: 1680px) {
  @keyframes Restricted {
    0% {
      transform: rotateZ(0.3deg);
    }
    25% {
      transform: rotateZ(-0.6deg);
    }
    50% {
      transform: rotateZ(0.6deg);
    }
    75% {
      transform: rotateZ(-0.6deg);
    }
    100% {
      transform: rotateZ(0.6deg);
    }
  }
}



@media screen and (max-width: 850px) {
  @keyframes Restricted {
    0% {
      transform: rotateZ(0.3deg);
    }
    25% {
      transform: rotateZ(-0.6deg);
    }
    50% {
      transform: rotateZ(0.6deg);
    }
    75% {
      transform: rotateZ(-0.6deg);
    }
    100% {
      transform: rotateZ(0.6deg);
    }
  }
}