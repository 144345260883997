.app_comment_card{
    width: 26%;
    position: relative;
    padding: 2rem 1.5rem;
    background-color: var(--color-blue);
    border: 3px;
    border-radius: 20px;
}
.app_comment_card_img{
    width: 100%;
    min-height: 100px;
    margin-left: 20%;
    margin-right: 20%;
}
.app_comment_card_img img{
    width: 60%;
    min-height: 100px;
    border: 2px;
    border-radius: 5%;
    align-items: center;
    margin-bottom: 1.3rem;
}
.app_comment_card_intro,.app_comment_card_name,.app_comment_card_comment{
    width: 100%;
    color: var(--color-white);
    text-align: center;    
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
}
.app_comment_card_body{
    min-height: 100px;
}

.app_comment_card_intro{
    padding:0.2rem;
    margin-bottom: 2rem;
}
.app_comment_card_comment{
    padding: 0.5rem 0.1rem;
    font-weight: 600;
    font-style: normal;
    min-height: 90px;
    font-weight: 400;
    font-size: 14px;
    bottom: 10px;
    text-align: justify;

}
@media screen and (max-width:1680px){
    .app_comment_card_intro,.app_comment_card_name{
        font-size: 20px;
        line-height: 22px;
    }
    .app_comment_card_comment{
        font-size: 18px;
        line-height: 26px;
    }

   
}
@media screen and (max-width: 1150px) {
    .app_comment_card_body{
        min-height: 150px;
    }
    .app_comment_card_intro,.app_comment_card_name{
        font-size: 18px;
        line-height: 20px;
    }
}
@media screen and (max-width: 1050px){
    .app_comment_card{
        width: 32%;
        min-height: 200px;
    }
    .app_comment_card_intro,.app_comment_card_name{
        font-size: 17px;
        line-height: 20px;
    }
    .app_comment_card_comment{
        font-size: 15px;
        line-height: 20px;
    }
}
@media screen and (max-width: 850px) {
    .app_comment_card{
        width: 32%;
        min-height: 200px;
    }
    .app_comment_card_intro,.app_comment_card_name{
        font-size: 15px;
        line-height: 20px;
    }
    .app_comment_card_comment{
        font-size: 14px;
        line-height: 18px;
    }
}


@media screen and (max-width: 650px) {
    .app_comment_card{
        width: 100%;
        margin-bottom: 10%;
    }
    .app_comment_card_intro,.app_comment_card_name,.app_comment_card_comment{
    font-size: 20px;
    line-height: 28px;
    }
    .app_comment_card_body{
        min-height: 130px;
    }

}