.app_blogdetails_body {
    width: 100%;
}

.app_BlogDetails_Comments {
    display: flex;
    justify-content: space-between;
    row-gap: 10%;
}

.app_blogdetails_header_img {
    width: 100%;
    position: relative;
}

.app_blogdetails_header_img_01 {
    position: absolute;
    width: 30%;
    left: 0;
    top: 0;
    z-index: -1;
}

.app_blogdetails_header_img_02 {
    position: absolute;
    width: 14%;
    right: 0;
    top: 0;
    z-index: -1;
}

.app_blogdetails_hero_img {
    width: 100%;
    z-index: 9;
}

.app_blogdetails_title {
    color: var(--color-blue);
    text-align: center;
    text-indent: -1em;
    /* padding: 2rem 0.15rem 0.15rem 2rem; */
    font-family: var(--font-main);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 54px;
    font-size: 39px;
}

.app_blogdetails_sec_section_desc p,
.app_blogdetails_third_section p,
.app_blogdetails_fourth_section p,
.app_blogdetails_fifth_section_desc p,
.app_blogdetails_seventh_section p {
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    text-align: justify;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.app_blogdetails_sec_section {
    display: flex;
    text-align: center;
    width: 100%;
}

.app_blogdetails_sec_section_desc {
    width: 90%;
    padding-right: 0px;

}

.app_blogdetails_sec_section_img {
    width: 80%;
    position: relative;
    float: right;
    min-height: 300px;
    display: flex;


    /* justify-content: center; */
}

.app_blogdetails_sec_section_img img {
    border-radius: 1rem;
    min-height: 200px;
}

.app_blogdetails_sec_section_bg {
    position: absolute;
    bottom: 15%;
    right: 0;
    width: 30%;
    z-index: -6;
}

.app_blogdetails_img_title,
.app_blogdetails_sec_section_img_title {
    background-color: var(--color-blue);
    position: absolute;
    width: 60%;
    min-height: 80px;
    bottom: -11.6%;
    left: 50%;
    padding: 1rem;
    transform: translate(-50%, -100%);
    color: var(--color-white);
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    border: 2px;
    border-radius: 8px;
}

.app_blogdetails_fourth_section .p__heading {
    color: var(--color-blue);
    font-family: var(--font-main);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 40px;
    font-size: 35px;
    padding-bottom: 1.2rem;
}

.app_blogdetails_fifth_section {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 1.3rem;
}

.app_blogdetails_fifth_section_img {
    min-height: 600px;
    width: 70%;
    position: relative;
}

.app_blogdetails_fifth_section_img_01 {
    position: absolute;
    width: 66% !important;
    left: 0;
    top: -10%;
    z-index: -9;
}

.app_blogdetails_fifth_section_img img {
    width: 110%;
    border: 2px;
    border-radius: 3%;
    min-height: 600px;
    padding-top: 0px;
}

.app_blogdetails_fifth_section_desc_title {
    font-family: var(--font-main);
    color: var(--color-blue);
    font-style: normal;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
}

.app_blogdetails_fifth_section_desc {
    width: 100%;
    /* padding:0 2rem; */
}

.padding_left {
    padding-left: 1.6rem;
}

.app_blogdetails_sixth_section {
    background-color: var(--color-blue);
    border: 2px;
    border-radius: 20px;
}

.app_blogdetails_sixth_section_img {
    width: 100%;
    display: flex;

    align-items: center;

}

.app_blogdetails_sixth_section_parent {
    position: relative;
}

.app_blogdetails_sixth_section_01,
.app_blogdetails_sixth_section_03,
.app_blogdetails_sixth_section_03 {
    padding: 1.9rem;
}

.app_blogdetails_sixth_section_01,
.app_blogdetails_sixth_section_03 {
    min-height: 400px;
}

.app_blogdetails_sixth_section_01 img,
.app_blogdetails_sixth_section_03 img {
    min-height: 210px;
    border-radius: 10px;

}

.app_blogdetails_sixth_section_01 p,
.app_blogdetails_sixth_section_03 p {
    position: absolute;
    bottom: 3%;
    left: 7%;
    right: 7%;
    color: var(--color-white);
}

.app_blogdetails_sixth_section_02 {
    min-height: 420px;
}

.app_blogdetails_sixth_section_02 img {
    min-height: 422px;
    border-radius: 3%;
}

.app_blogdetails_sixth_section_child {
    width: 48%;
    text-align: center;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-white);
    color: var(--color-blue);
    font-family: var(--font-main);
    font-style: normal;
    font-weight: 600;
    font-size: 43px;
    line-height: 100px;
    border: 2px;
    border-radius: 10px;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.2);
}

.app_blogdetails_sixth_section_footer {
    display: flex;
    justify-content: space-between;
}

.app_blogdetails_sixth_section_footer p {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--color-white);
    display: flex;
    align-items: center;
}

.app_blogdetails_Photo_Gallery {
    position: relative;
    padding-top: 0;
}

.app_blogdetails_Photo_Gallery_bg {
    position: absolute;
    top: 2%;
    left: 0;
    width: 20%;
    z-index: -6;
}

.app_blogdetails_Photo_Gallery p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-main);
    color: var(--color-blue);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.app_blogdetails_Photo_Gallery_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.app_blogdetails_Photo_Gallery_flex_01 {
    min-height: 746px;
}

.app_blogdetails_Photo_Gallery_flex img {
    padding: 1rem;
}

.app_blogdetails_Photo_Gallery_flex_03 {
    width: 75%;
}

.app_blogdetails_Photo_Gallery_flex_div {
    width: 100%;
}

.app_blogdetails_Photo_Gallery_flex_02 {
    min-height: 332px;
    margin-right: 1rem;
}

.app_blogdetails_Photo_Gallery_div {
    width: 84%;
    padding: 1rem;
    padding-left: 0;
}

.app_blogdetails_Photo_Gallery_div img {
    min-height: 300px;
}

.app_blogdetails_Photo_Gallery_flex img {
    width: 100%;
}

.app_blogdetails_Photo_Gallery_flex_04 {
    padding-top: 1rem;
}

.app_blogdetails_Photo_Gallery_img_border {
    border: 2px;
    border-radius: 2rem;
}

.app_blogdetails_comments_title,
.app_blogdetails_contact_details {

    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-main);
    color: var(--color-blue);
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
}

.app_blogdetails_contact_details {
    margin-bottom: 3rem;
    font-size: 24px;
    line-height: 36px;
}

.app_blogdetails_contact_details a {
    color: var(--color-blue);
}



@media screen and (max-width: 1440px) {
    .app_blogdetails_sec_section_img_title {
        bottom: -17%;
    }

    .app_blogdetails_img_title {
        bottom: -15%;
    }

    .app_blogdetails_sixth_section_child {
        top: -3.4%
    }

    .app_blogdetails_sixth_section_footer p {
        padding-left: 3rem;
    }

    .app_blogdetails_Photo_Gallery_img_border {
        border: 2px;
        border-radius: 2rem;
        width: 50% !important;
    }

    .app_blogdetails_Photo_Gallery_flex_03 {
        width: 80%;
    }

    .app_blogdetails_Photo_Gallery_div {
        width: 89%;
        padding: .7rem;
        padding-left: 0;
    }

    .app_blogdetails_sec_section_desc p,
    .app_blogdetails_third_section p,
    .app_blogdetails_fourth_section p,
    .app_blogdetails_fifth_section_desc p,
    .app_blogdetails_seventh_section p {
        font-size: 19px;
        line-height: 34px;
    }

    .app_blogdetails_sixth_section_footer p:last-child {
        padding-right: 0rem;
    }

    .app_blogdetails_Photo_Gallery_flex_01 {
        min-height: 600px;
    }

    .app_blogdetails_Photo_Gallery_01 {
        width: 50% !important;
    }

    .app_blogdetails_fifth_section {
        margin-bottom: 5rem;
    }
}



@media screen and (max-width:1380px) {
    .app_blogdetails_sec_section_img_title {
        bottom: -15%;
    }

    .app_blogdetails_img_title {
        bottom: -12%;
    }

}



@media screen and (max-width: 1150px) {
    .app_blogdetails_sec_section_img_title {
        bottom: -17%;
    }

    .app_blogdetails_sec_section_bg {
        bottom: 3%;
    }

    .app_blogdetails_img_title {
        bottom: 2%;
    }

    .app_blogdetails_sixth_section_child {
        width: 40%;
        font-size: 30px;
        line-height: 60px;
        top: .2%
    }

    .app_blogdetails_Photo_Gallery_01 {
        width: 50% !important;
    }

    .app_blogdetails_Photo_Gallery_flex_01 {
        min-height: 500px;
    }

    .app_blogdetails_sixth_section_footer {
        justify-content: space-around;
        /* row-gap: 3rem; */
        padding: 0;
        margin: 0;
        text-align: center;
        padding-left: 1rem;
        width: 100%;
    }

    .app_blogdetails_sixth_section_footer_01 {
        padding-right: 3.5rem;
    }

    .app_blogdetails_Photo_Gallery_bg {
        top: 4%;
        width: 21%;
    }

    .app_blogdetails_fifth_section_desc_title {
        font-size: 32px;
        line-height: 38px;
    }
}



@media screen and (max-width: 1025px) {
    .app_blogdetails_sec_section_img_title {
        bottom: -23%;
    }

    .app_blogdetails_Photo_Gallery_bg {
        top: 4%;
        width: 21%;
    }

    .app_blogdetails_sixth_section_footer p {
        font-size: 20px;
        line-height: 15px;

    }

    .app_blogdetails_sixth_section_footer {
        justify-content: space-around;
        /* row-gap: 3rem; */
        padding: 0;
        margin: 0;
        text-align: center;
        padding-left: 1rem;
        width: 100%;
    }

    .app_blogdetails_sixth_section_footer_01 {
        padding-right: 3.5rem;
    }

    .app_blogdetails_fifth_section {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .app_blogdetails_sec_section {
        display: inline;
        text-align: center;
        width: 100%;
    }

    .app_blogdetails_sec_section_desc {
        width: 100%;
        padding-right: 2rem;
    }

    .app_blogdetails_sec_section_img {
        width: 60%;
    }
}



@media screen and (max-width: 850px) {
    .app_blogdetails_title {
        text-indent: 0;
        line-height: 40px;
        font-size: 30px;
    }

    .app_blogdetails_sec_section_img_title {
        bottom: -44%;
    }

    .app_blogdetails_fifth_section {
        display: block;
    }

    .app_blogdetails_fifth_section_img {
        width: 100%;
        min-height: 400px;
        margin-bottom: 2rem;
    }

    .app_blogdetails_fifth_section_img .flex__center {
        margin-left: 20%;
        margin-right: 20%;
    }

    .app_blogdetails_fifth_section_img .flex__center img {
        min-height: 300px !important;
    }

    .app_blogdetails_img_title {
        bottom: -30%;
        width: 38%;
    }

    .app_blogdetails_sixth_section_child {
        width: 49%;
        font-size: 28px;
        line-height: 50px;
        top: 2%
    }

    .app_blogdetails_sixth_section_footer p {
        font-size: 17px;
        line-height: 25px;
        padding-left: 1.3rem;
    }

    .app_blogdetails_Photo_Gallery_flex_01,
    .app_blogdetails_Photo_Gallery_flex_02 {
        min-height: 200px !important;
    }

    .app_blogdetails_Photo_Gallery_img_border {
        border: 2px;
        border-radius: 1rem;
        width: 50% !important;
    }

    .app_blogdetails_Photo_Gallery div img {
        padding: 0.3rem;
        margin: 0;
    }
}



@media screen and (max-width: 650px) {
    .app_BlogDetails_Comments {
        flex-direction: column;
    }
}



@media screen and (max-width: 480px) {
    .app_blogdetails_title {
        text-indent: 0;
        line-height: 36px;
        font-size: 26px;
    }
}
