.app__landing {
    width: 100%;
    height: calc(100vh - 5rem);
    /* padding-top: 30%; */
    background-color: var(--color-white);
    overflow: hidden;

    position: relative;
}

.app__landing .app__landing-carousel {
    width: 100%;
    margin: -8% 0 0;
    position: static;
}

.app__landing-carousel .react-multi-carousel-dot-list {
    position: absolute;
    z-index: 10;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.app__landing-carousel .react-multi-carousel-dot button {
    width: 28px;
    height: 10px;
    margin: 0 5px;
    border-radius: 8px;
}

/* .app__landing-carousel .react-multi-carousel-dot--active button {
    background: var(--color-royalblue);
} */

.app__landing .app__landing-carousel img {
    width: 100%;
    object-fit: cover;
}

.app__landing_signup-button, .app__landing_registration-button {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 36%;
    padding-left: 3.5%;

    column-gap: 3rem;
}

.app__landing_registration-button {
    bottom: 30%;
}

.app__landing_signup-button .landing__signup_button {
    padding: 1rem 2.5rem;
    background-color: var(--color-darkblue);
    border: none;
    border-radius: .2rem;
    outline: none;
    font-family: var(--font-main);
    font-size: 20px;
    font-weight: 500;
    color: var(--color-white);
    transition: 0.1s;
    cursor: pointer;
}

.app__landing_registration-button .landing__register_button {
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: var(--font-main);
    font-size: 22px;
    font-weight: 500;
    color: var(--color-black);
    transition: 0.1s;
    cursor: pointer;
}

.app__landing_registration-button .landing__register_button .right__arrow {
    margin-left: 5px;
    padding-top: 5px;
}

.app__landing_registration-button .landing__register_button:hover .right__arrow {
    margin-left: 10px;
    transition: 0.2s;
}

.app__landing_signup-button .landing__signup_button:hover {
    background-color: var(--color-blue);
    transition: 0.3s;
}

.app__landing_signup-button .landing__signup_button:active {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.app__landing_hero {
    width: 60%;

    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-45%);
}

.app__landing_content {
    width: 55%;
    height: 70%;

    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
}

.app__landing_content-illustration {
    background-color: var(--color-blue);
    opacity: 0.4;
    border-radius: 4px;
    position: absolute;
}

#illustration-1 {
    width: 50%;
    height: 20%;

    top: 0;
    right: 0;
}

#illustration-2 {
    width: 10%;
    height: 50%;

    bottom: 0;
    left: 0;
}

.app__landing_content-title {
    width: 85%;
    height: fit-content;
    padding: 4rem;
    background-color: rgb(47, 159, 226, 0.9);
    border-radius: 4px;
    opacity: 0.8;
}



@media screen and (min-width: 2000px) {
    .app__landing {
        height: calc(100vh - 6rem);
    }

    .app__landing-carousel .react-multi-carousel-dot button {
        width: 40px;
        height: 15px;
        border-radius: 10px;
    }

    /* .app__landing_registration-button {
        bottom: 35%;
        padding-left: 82px;
    } */
}



@media screen and (max-width: 1680px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: -5% 0 0;
    }

    .app__landing_signup-button, .app__landing_registration-button {    
        column-gap: 2rem;
    }

    .app__landing_signup-button .landing__signup_button {
        padding: 0.8rem 2rem;
        font-size: 18px;
    }
}



@media screen and (max-width: 1280px) {
    .app__landing-carousel .react-multi-carousel-dot button {
        width: 25px;
        height: 10px;
        border-radius: 8px;
    }
}



@media screen and (max-width: 1150px) {
    .app__landing {
        height: calc(100vh - 5rem);
    }
    
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: -15% 0 0;
    }

    .app__landing_hero {
        width: 160%;

        top: 0;
        left: -40%;
        transform: translate(0, 0);
    }
    
    .large__banner {
        margin: 10% 0 0;
    }

    .app__landing_content {
        width: 80%;
        height: 50%;
    
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
    }

    .app__landing_content-illustration {
        opacity: 0.3;
    }

    .app__landing_content-title {
        padding: 3rem;
        background-color: rgb(47, 159, 226, 0.8);
    }
}



@media screen and (max-width: 850px) {
    .app__landing-carousel .react-multi-carousel-dot-list {
        bottom: 20px;
    }

    .app__landing-carousel .react-multi-carousel-dot button {
        width: 30px;
        height: 10px;
        border-radius: 8px;
    }

    .app__landing_content {
        width: 80%;
        height: 40%;
    }
}



@media screen and (max-width: 650px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: -12% 0 0;
    }

    .app__landing_content {
        height: 70%;
    }
}



@media screen and (max-width: 480px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: -5% 0 0;
    }

    .app__landing-carousel .react-multi-carousel-dot button {
        width: 20px;
        height: 8px;
        border-radius: 5px;
    }
}



@media screen and (max-width: 450px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: 10% 0 0;
    }
}



@media screen and (max-width: 400px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: 8% 0 0;
    }

    .app__landing_content {
        height: 60%;
    }
}



@media screen and (max-width: 340px) {
    .app__landing .app__landing-carousel {
        width: 100%;
        margin: -10% 0 0;
    }
}