.app__navbar_container {
    width: 100%;

    position: sticky;
    top: 0;
    left: 0;
    z-index: 99999;
}

.app__registrationpopup {
    width: 100%;
    height: max-content;
    padding: 0.5rem 0 0.8rem;
    background-color: var(--color-lightblue);

    position: relative;
    display: none;
}

.app__registrationpopup-info {
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
}

.app__registrationpopup-info p {
    height: auto;
    margin-bottom: 0.3rem;
    color: var(--color-black);
    font-weight: 400;

    /* transform: scale(0.9); */
}

.app__registrationpopup-info-button {
    width: fit-content;
    margin: auto;
    border-radius: 4px;
    display: block;
    transition: 0.1s;
}

.app__registrationpopup-info-button button {
    width: fit-content;
    padding: 0.2rem 1.2rem;
    color: var(--color-black);
    background-color: var(--color-yellow);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    font-family: var(--font-main);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.1s;
}

.app__registrationpopup-info-button:hover button {
    background-color: var(--color-lightyellow);
    transition: 0.3s;
}

.app__registrationpopup-info-button:active {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.app__registrationpopup-close {
    width: 5%;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    color: var(--color-black);

    position: absolute;
    top: 50%;
    right: 1%;
    transform: translateY(-50%);
    z-index: 100;
}

.app__navbar {
    width: 100%;
    height: 5rem;
    padding: 0rem 1.6rem 0rem 2rem;
    box-shadow: 1px 2px 12px rgba(36, 79, 105, 0.2);
    background-color: var(--color-white);


    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.app__navbar_title {
    width: 22rem;
    height: 100%;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__navbar_title-logo {
    width: 100%;
    margin-right: 1rem;
}

.app__navbar_title-name {
    height: auto;
}

.app__navbar_navigation {
    width: 80%;
    min-height: 5rem;
    padding: 0;
    justify-content: space-between;
}

.app__navbar_navigation-links {
    flex-direction: row;
}

.app__navbar_navigation-links > * {
    margin-left: 0.2rem;
    position: relative;
}

/* .app__navbar_navigation-links link,
.app__navbar_navigation-links p,
.app__navbar_navigation-links .p__navigation_small {
    width: 100%;
    height: 100%;
} */

.app_searchbar {
    width: 18rem;
}

.app__searchbar input {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    border: 1px solid var(--color-blue);
    border-radius: 4px;
    outline: none;
    font-size: 1.2rem;
    color: var(--color-blue);
    z-index: 1;
}

.app__searchbar ::-webkit-input-placeholder {
    color: var(--color-blue);
    opacity: 0.6;
}

.search__icon {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--color-blue);
    position: absolute;
    right: 2%;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.8;
}

.app__navbar_navigation-menu {
    width: 10rem;
    height: 100%;
    transition: ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.shift__down_courses {
    margin-top: 6.8rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.shift__down_courses p:first-child {
    background-color: var(--color-lightblue);
}

.shift__down_aboutUs {
    margin-top: 13.4rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.shift__down_aboutUs p:first-child {
    background-color: var(--color-lightblue);
}

.shift__down_whyUs {
    margin-top: 10.1rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.shift__down_whyUs p:first-child {
    background-color: var(--color-lightblue);
}

.shift__down_aboutUs p:last-child,
.shift__down_whyUs p:last-child,
.shift__down_courses p:last-child {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.app__navbar_navigation-menu p {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    text-align: left;
    cursor: pointer;

    justify-content: flex-start;
}

.app__navbar_navigation-menu p Link {
    width: 100%;
    height: 100%;
}

.app__navbar_navigation-menu p:first-child {
    width: 100%;
    min-height: 5rem;
    padding: 0.8rem 0;

    justify-content: center;
}

.app__navbar_navigation-menu p:hover {
    transition: 0.2s;
    background-color: var(--color-lightblue);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.app__navbar_subnavigation-menu {
    width: 20rem;
    height: auto;
    /* margin: 20rem 0 0 50rem; */
    background-color: var(--color-white);
    text-align: left;
    cursor: pointer;

    position: absolute;
    top: 4.9rem;
    left: 10rem;
}

.app__navbar_subnavigation-menu p:first-child {
    min-height: auto;
    padding: 1.2rem;
    background-color: var(--color-white);
    transition: 0.2s;
}

.app__navbar_subnavigation-menu p:hover {
    transition: 0.2s;
    background-color: var(--color-lightblue);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.selected_subnavigation Link {
    transition: 0.2s;
    background-color: var(--color-lightblue) !important;
}

.shift__down_courses p {
    width: 100%;
    margin-left: 0rem;
    padding: 0.8rem 1.2rem;
}

.shift__down_courses p:first-child {
    width: 100%;
    margin-left: 0;
}

.shift__down_aboutUs p {
    width: 100%;
    margin-left: 0rem;
    padding: 0.8rem 1.2rem;
}

.shift__down_aboutUs p:first-child {
    width: 100%;
    margin-left: 0;
}

.shift__down_whyUs p {
    width: 200%;
    margin-left: 10rem;
    padding: 0.8rem 1.7rem;
}

.shift__down_whyUs p:first-child {
    width: 100%;
    margin-left: 0;
}

.dropdown_arrow {
    color: var(--color-blue);
    font-size: 2rem;
}

.app__navbar_navigation-button {
    /* width: 10rem; */
    margin-right: 0.8rem;
    display: block;
}

.app__navbar_navigation button {
    width: 100%;
}

.app__navbar_navigation-small {
    width: 10%;
}

.hamburger {
    float: right;
    font-size: 40px;
    color: var(--color-blue);
    cursor: pointer;
}

.app__navbar_overlay {
    width: 100%;
    max-height: 100vh;
    padding-bottom: 2rem;
    background-color: var(--color-white);
    transition: 0.1s;

    position: fixed;
    top: 5rem;
    left: 0;
    z-index: -1;
}

.app__navbar_overlayclose {
    transition: 0.1s;

    position: fixed;
    top: -150%;
    left: 0;
    z-index: -1;
}

.app__navbar_overlay-close {
    font-size: 70px;
    font-weight: 600;
    color: var(--color-blue);
    cursor: pointer;
}

.app__navbar_overlay-navigation {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    text-align: left;
}

.app__navbar_overlay-navigation link,
.app__navbar_overlay-navigation p,
.app__navbar_overlay-navigation .p__navigation_small {
    width: 100%;
    height: 100%;
}

.hidden{
    display: none;
}

/* .app__navbar_navigation-menu .flex__center  .nav__button{
    padding: 0.4rem;
    margin: 1rem;
    background-color: var(--color-darkblue);
    border: none;
    border-radius: .2rem;
    outline: none;
    font-family: var(--font-main);
    font-size: 16px;
    font-weight: 500;
    color: var(--color-white);
    transition: 0.1s;
    cursor: pointer;
} */

.app__userdetails {
    flex-direction: column;
    align-items: flex-end;
}



@media screen and (min-width: 2000px) {
    .app__registrationpopup-info-button button {
        padding: 0.3rem 1.4rem;
        font-size: 18px;
    }

    .app__registrationpopup-close {
        font-size: 2.5rem;
    }

    .app__navbar {
        height: 6rem;
    }

    .app__navbar_title {
        width: 30rem;
    }

    .app__navbar_navigation {
        min-height: 6rem;
    }

    .app_searchbar {
        width: 18rem;
    }
    
    .app__searchbar input {
        padding: 0.6rem 4rem 0.8rem 0.5rem;
        font-size: 1.6rem;
    }

    .app__navbar_navigation-menu {
        width: 14rem;
    }

    .app__navbar_navigation-menu p:first-child {
        min-height: 6rem;
    }

    .shift__down_whyUs p {
        width: 180%;
        margin-left: 11.2rem;
        padding: 0.8rem 1.7rem;
    }

    .search__icon {
        font-size: 2.2rem;
    }

    .shift__down_courses {
        margin-top: 9.6rem;
    }

    .shift__down_aboutUs {
        margin-top: 18.9rem;
    }

    .shift__down_whyUs {
        margin-top: 14.2rem;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1400px) {
    .app__navbar_navigation-menu {
        width: 10rem;
    }
}



@media screen and (max-width: 1150px) {
    .app__navbar_title {
        width: 20rem;
    }

    .app__registrationpopup {
        display: block;
    }

    .app__registrationpopup-info-button button {
        padding: 0.2rem 1.2rem;
        font-size: 14px;
    }

    .app__registrationpopup-close {
        font-size: 1.8rem;
    }

    .app__navbar_navigation-menu p:hover {
        transition: 0.2s;
        background-color: var(--color-lightblue);
        box-shadow: none;
    }

    .shift__down_courses {
        margin-top: 5.6rem;
    }

    .shift__down_aboutUs {
        margin-top: 11.4rem;
    }

    .shift__down_whyUs {
        margin-top: 8.4rem;
    }

    .shift__down_whyUs p {
        width: 170%;
        margin-left: 7rem;
    }

    /* .app__navbar_navigation-button {
        width: 8rem;
    } */
    
    .app__navigation_menu-smalldevices {
        padding: 0.4rem 0 0.4rem 1rem;
    }

    .app__navbar_overlay-close {
        font-size: 3rem;
    }

    .app__navbar_navigation-menu .dropdown_arrow {
        font-size: 1.8rem !important;
    }
    .p__navigation_user{
        font-size: 15px !important;
        line-height:19px !important;
    }

    .app__navbar_subnavigation-menu p:first-child {
        padding-left: 0rem;
    }
}



@media screen and (max-width: 950px) {
    .app__navbar_title {
        width: 22rem;
    }

    .hamburger {
        font-size: 34px;
    }
}



@media screen and (max-width: 850px) {
    .app__registrationpopup-info-button button {
        padding: 0.2rem 1.2rem;
        font-size: 12px;
    }

    .app__registrationpopup-close {
        font-size: 1.5rem;
    }

    .app__navbar_title {
        width: 24rem;
    }

    .app__navbar_overlay-navigation {
        width: 100%;
        padding: 0;
        transform: scale(1);
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        width: 100%;
        height: 3.5rem;
        padding: 0 2.7rem 0 2rem;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu p {
        width: 100%;
        height: auto;
        background-color: transparent !important;
        justify-content: space-between;

        transition: 0.1s;
    }

    .app__navbar_overlay-close {
        font-size: 2.5rem;
    }

    .app__navbar_navigation-menu .dropdown_arrow {
        font-size: 2.3rem !important;
    }
}



@media screen and (max-width: 650px) {
    .app__registrationpopup-info p {
        width: 70%;
    }

    .app__registrationpopup-info-button button {
        font-size: 14px;
    }

    .app__registrationpopup-close {
        font-size: 1.5rem;
        right: 2.5%;
    }

    .app__navbar {
        height: 5rem;
        padding: 0rem 1rem 0rem 1rem;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        padding: 0 1.6rem 0 2rem;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu p {
        height: auto;
        margin: 0;
        font-size: 20px;
    }

    .hamburger {
        font-size: 26px;
    }

    .app__navbar_overlay-close {
        font-size: 2rem;
    }

    .app__navbar .dropdown_arrow {
        font-size: 2rem !important;
    }
}



@media screen and (max-width: 600px) {
    .app__registrationpopup-info p {
        width: 65%;
    }
}



@media screen and (max-width: 500px) {
    .app__registrationpopup-info p {
        width: 70%;
    }

    .app__registrationpopup-info-button button {
        font-size: 14px;
    }

    .app__registrationpopup-close {
        width: 5.5%;
        font-size: 5rem;
        right: 3%;
    }

    .app__navbar_title {
        width: 20rem;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        padding: 0 1.4rem 0 2rem;
    }
}



@media screen and (max-width: 450px) {
    .app__registrationpopup-close {
        width: 6%;
        font-size: 5rem;
        right: 4%;
    }
    
    .app__navbar_title {
        width: 18rem;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        padding: 0 1.2rem 0 2rem;
    }

    .app__navbar_navigation-menu p {
        width: 100%;
    }

    .hamburger {
        font-size: 24px;
    }

    .app__navbar_overlay-close {
        font-size: 1.8rem;
    }
}



@media screen and (max-width: 380px) {
    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        padding: 0 1rem 0 2rem;
    }
}



@media screen and (max-width: 350px) {
    .app__registrationpopup-info p {
        width: 70%;
    }
    
    .app__registrationpopup-info-button button {
        font-size: 14px;
    }

    .app__registrationpopup-close {
        width: 6%;
    }

    .app__navbar_overlay-navigation .app__navbar_navigation-menu {
        padding: 0 1.4rem 0 2rem;
    }
}
