.app__contactus-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
}

.app__contactus {
    width: 80%;
    height: 80%;
    border-radius: 6px;

    flex-direction: row;

    position: relative;
}

.app__contactus_info {
    width: 40%;
    height: 100%;
    padding: 5rem;
    background-color: var(--color-blue);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow: hidden;

    flex-direction: column;

    position: relative;
}

.app__contactus_info-illustration {
    width: 80%;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.app__contactus_info-heading {
    width: 100%;
    height: 30%;
    font-weight: 600;
    color: var(--color-white);
    position: relative;
    z-index: 2;
    
    justify-content: flex-start;
}

.app__contactus_info-details {
    width: 100%;
    height: 30%;
    margin-top: 3rem;
    color: var(--color-white);
    position: relative;
    z-index: 2;

    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.app__contactus_info-details p {
    width: 100%;
    font-weight: 600;
    text-transform: initial;
}

.app__contactus_info-details .contactus__icons {
    margin-right: 3rem;
    font-size: 2.5rem !important;
}

.app__contactus_form-container {
    width: 60%;
    height: 100%;
    padding: 3rem;
    background-color: var(--color-lightblue);

    flex-direction: column;
    justify-content: space-between;
}

.app__contactus_form {
    width: 100%;
    height: 70%;
}

.app__contactus_form-row {
    width: 100%;
    margin-bottom: 4rem;

    flex-direction: row;
}

.app__contactus_form-row-item {
    width: 50%;

    flex-direction: column;
    align-items: flex-start;
}

.app__contactus_form-row-item label {
    margin-bottom: 0.5rem;
    color: var(--color-grey);
    font-weight: 500;
}

.app__contactus_form-input {
    width: 90%;
    padding-bottom: 0.5rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--color-grey);
    font-family: var(--font-main);
    font-size: 20px;
}

.app__contactus_form-subheading {
    margin-bottom: 2rem;
    font-weight: 600;
}

.app__contactus_form-selector {
    flex-direction: row;
    justify-content: flex-start;
}

.app__contactus_form-selector label {
    margin-right: 3rem;
    font-weight: 400;
    cursor: pointer;
}

.app__contactus_form-selector input {
    width: 24px;
    height: 24px;
    background-color: black;
}

.app__contactus_form-message {
    margin-top: 3rem;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.app__contactus_form-message label {
    font-weight: 600;
}

.app__contactus_form-message-input {
    width: 90%;
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 2px solid var(--color-grey);
    font-family: var(--font-main);
    font-size: 18px;
}

.app__contactus_form-button {
    width: 100%;
    height: 30%;

    justify-content: flex-end;
    align-items: flex-end;
}

.app__contactus_form-button button {
    padding: 1rem 2rem;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    border: none;
    background-color: var(--color-yellow);
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    transition: 0.1s;
    cursor: pointer;
}

.app__contactus_close {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
}



@media screen and (min-width: 2000px) {
    .app__contactus_info-details {
        height: 40%;
    }

    .app__contactus_info-details .contactus__icons {
        font-size: 3rem !important;
    }

    .app__contactus_form-message-input {
        font-size: 20px;
    }

    .app__contactus_form-button button {
        padding: 1rem 2rem;
        font-size: 22px;
    }
}



@media screen and (max-width: 1680px) {
    .app__contactus_info-details .contactus__icons {
        margin-right: 2rem;
    }

    .app__contactus_form-selector input {
        width: 18px;
        height: 18px;
    }

    .app__contactus_form-selector label {
        margin-right: 2rem;
    }

    .app__contactus_form-input {
        padding-bottom: 0.2rem;
    }

    .app__contactus_form-message-input {
        padding-bottom: 0.2rem;
    }
}



@media screen and (max-width: 1440px) {
    .app__contactus_info-heading {
        font-size: 28px !important;
    }

    .app__contactus_info-details {
        height: 40%;
    }

    .app__contactus_info-details .contactus__icons {
        margin-right: 1.5rem;
        font-size: 2rem;
    }

    .app__contactus_form-row {
        margin-bottom: 2rem;
    }

    .app__contactus_form-row-item label {
        margin-bottom: 0.3rem;
    }

    .app__contactus_form-input {
        font-size: 16px;
    }

    .app__contactus_form-subheading {
        margin-bottom: 1rem;
    }
    
    .app__contactus_form-selector label {
        margin-right: 1rem;
    }

    .app__contactus_form-message {
        margin-top: 2rem;
    }

    .app__contactus_form-message-input {
        margin-top: 1rem;
    }

    .app__contactus_form-button button {
        padding: 0.8rem 1.6rem;
        font-size: 16px;
    }
}



@media screen and (max-width: 1380px) {
    .app__contactus_info {
        padding: 4rem;
    }

    .app__contactus_info-heading {
        font-size: 28px !important;
    }

    .app__contactus_info-details {
        margin-top: 0;
        height: 70%;
    }

    .app__contactus_info-details .contactus__icons {
        margin-right: 1.5rem;
        font-size: 1.5rem;
    }

    .app__contactus_info-details p {
        font-size: 20px;
        line-height: 30px;
    }

    .app__contactus_form-input {
        padding-bottom: 0.1rem;
        font-size: 16px;
    }

    .app__contactus_form-selector input {
        width: 16px;
        height: 16px;
    }

    .app__contactus_form-selector label {
        margin-right: 1rem;
        font-size: 16px !important;
    }

    .app__contactus_form-message-input {
        font-size: 16px;
    }
}



@media screen and (max-width: 1280px) {
    .app__contactus_info-heading {
        font-size: 26px !important;
    }
}



@media screen and (min-width: 991px) and (max-width: 1150px) and (max-height: 1000px) {
    .app__contactus {
        flex-direction: column;
    }

    .app__contactus_info {
        width: 100%;
        height: 35%;
    }

    .app__contactus_info-heading {
        height: auto;
        margin: -3rem 0 0 -6rem;
        transform: scale(0.8);
    }

    .app__contactus_info-details {
        width: 110%;
        height: auto;
        margin: 0rem 0 0 -7rem;
        transform: scale(0.8);

        flex-direction: row;
        justify-content: space-between;
    }

    .app__contactus_info-details-item {
        width: 25%;
    }

    .app__contactus_info-details-item:last-child {
        width: 45%;

        align-items: flex-start;
    }

    .app__contactus_info-details-item .contactus__icons {
        width: 15%;
    }

    .app__contactus_info-details-item:last-child .contactus__icons {
        width: 8%;
    }

    .app__contactus_form-container {
        width: 100%;
        height: 65%;
    }

    .app__contactus_form {
        margin: -3rem 0 0 -6rem;
        transform: scale(0.7);
    }

    .app__contactus_form-selector input {
        width: 20px;
        height: 20px;
    }

    .app__contactus_form-button {
        margin: 5rem 0 0 4rem;
        transform: scale(0.8);
    }
}



@media screen and (min-width: 850px) and (max-width: 1150px) and (min-height: 1000px) {
    .app__contactus {
        width: 80%;
        height: 70%;
        flex-direction: column;
    }

    .app__contactus_info {
        width: 100%;
        height: 40%;
        padding: 3rem;
    }

    .app__contactus_form-container {
        width: 100%;
        height: 60%;
    }

    .app__contactus_form-selector label {
        margin-right: 4rem;
    }

    .app__contactus_form-selector input {
        width: 20px;
        height: 20px;
    }
}



@media screen and (max-width: 850px) {
    .app__contactus {
        width: 80%;
        height: 80%;
        flex-direction: column;
    }

    .app__contactus_info {
        width: 100%;
        height: 40%;
        padding: 2rem;
    }

    .app__contactus_form-container {
        width: 100%;
        height: 60%;
        padding: 2rem;
    }

    .app__contactus_form-subheading {
        font-size: 18px;
    }

    .app__contactus_form-selector label {
        margin-right: 1rem;
    }

    .app__contactus_form-message label {
        font-size: 18px;
    }

    .app__contactus_form-button button {
        padding: 0.8rem 1.5rem;
        font-size: 16px;
    }
}



@media screen and (max-width: 650px) {
    .app__contactus_info {
        width: 100%;
        height: 35%;
        padding: 0rem 2rem;
    }

    .app__contactus_info-details {
        width: 100%;
        height: 80%;
        margin: -2rem 0 0 -1rem;
        transform: scale(0.9);
    }

    .app__contactus_form-container {
        width: 100%;
        height: 65%;
        padding: 1rem;
    }

    .app__contactus_form-selector input {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
    }

    .app__contactus_form-button button {
        padding: 0.8rem 1rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 480px) {
    .app__contactus_info {
        width: 100%;
        height: 30%;
        padding: 0rem 2rem;
    }

    .app__contactus_info-heading {
        margin: 0 0 0 -2rem;
        transform: scale(0.8);
    }

    .app__contactus_info-details {
        margin: -2rem 0 0 -3rem;
        transform: scale(0.7);
    }

    .app__contactus_form-container {
        width: 100%;
        height: 70%;
    }

    .app__contactus_form-button button {
        padding: 0.8rem 0.6rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 450px) {
    .app__contactus_info {
        width: 100%;
        height: 30%;
        padding: 0rem 2rem;
    }

    .app__contactus_info-heading {
        width: 150%;
        margin: -0.5rem 0 1rem -3rem;
        transform: scale(0.8);
    }

    .app__contactus_info-details {
        margin: -2rem 0 0 -3rem;
        transform: scale(0.7);
    }

    .app__contactus_form-container {
        width: 100%;
        height: 70%;
    }

    .app__contactus_form {
        margin: -1rem 0 1rem -2rem;
        transform: scale(0.8);
    }

    .app__contactus_form-button button {
        padding: 0.8rem 0.6rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 360px) {
    .app__contactus_info {
        width: 100%;
        height: 30%;
        padding: 0rem 2rem;
    }

    .app__contactus_info-heading {
        width: 150%;
        margin: -0.5rem 0 1rem -5rem;
        transform: scale(0.6);
    }

    .app__contactus_info-details {
        margin: -2rem 0 0 -5rem;
        transform: scale(0.4);
    }

    .app__contactus_form-container {
        width: 100%;
        height: 70%;
    }

    .app__contactus_form {
        width: 130%;
        margin: -1rem 0 1rem -3rem;
        transform: scale(0.7);
    }

    .app__contactus_form-button button {
        padding: 0.6rem 0.4rem;
        font-size: 12px;
    }
}