.app__learningcard {
    width: 100%;
    margin-bottom: 2rem;
    padding: 2.8rem 1rem 2.7em;
    background-color: var(--color-white);
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.app__learningcard_informationcontainer {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.app__learningcard_illustration {
    width: 27.6%;
}

.app__learningcard_information {
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.app__learningcard_information-title {
    margin: 1rem 0 0.6rem;
}

.app__learningcard_information-description {
    font-size: 18px;
    line-height: 25px;
}

.app__learningcard_navigation {
    width: 30%;
}

.app__learningcard_navigation button {
    width: 100%;
    /* margin-top: 0.6rem; */
    padding: 0.8rem 0;
    color: var(--color-blue);
    background-color: var(--color-white);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(27, 63, 84, 0.2) 0px 2px 4px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s;
}

.app__learningcard_navigation button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
}

.app__learningcard_navigation button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.app__learningcard_navigate {
    margin-top: 1rem;
    color: var(--color-blue);
    font-weight: 500;
    line-height: 0;
}

.app__learningcard_navigate:hover {
    color: var(--color-darkblue);
}

.dropdown_arrow {
    margin-left: 0.1rem;
    font-size: 1.8rem;
}

.app__learningcard_navigate:hover .dropdown_arrow {
    color: var(--color-darkblue);
    margin-left: 0.5rem;
    transition: 0.3s;
}



@media screen and (min-width: 2000px) {
    .app__learningcard {
        width: 100%;
        padding: 4rem 1rem 3.5rem;
        border-radius: 20px;
    }

    .app__learningcard_illustration {
        width: 30%;
    }

    .app__learningcard_information-title {
        margin: 2rem 0 0.6rem;
    }

    .app__learningcard_information-description {
        font-size: 22px;
        line-height: 35px;
    }

    .app__learningcard_navigation {
        width: 40%;
        margin-top: 3rem;
    }

    .app__learningcard_navigation button {
        margin-top: 0rem;
        padding: 1rem 0;
        border-radius: 6px;
        font-size: 22px;
    }

    .app__learningcard_navigate {
        margin-top: 2rem;
    }

    .dropdown_arrow {
        font-size: 2.5rem;
    }
}



/* @media screen and (min-width: 2200px) and (max-width: 2450px) {
    .app__learningcard {
        margin-bottom: 10rem;
    }
}



@media screen and (min-width: 2200px) {
    .app__learningcard_navigation {
        margin-top: 1rem;
    }
}



@media screen and (min-width: 2450px) {
    .app__learningcard {
        margin-bottom: 5rem;
    }

    .app__learningcard_illustration {
        width: 28.5%;
    }

    .app__learningcard_navigation {
        width: 40%;
    }

    .app__learningcard_navigation button {
        padding: 0.8rem 0;
        font-size: 24px;
    }
} */



@media screen and (min-width: 1151px) and (max-width: 1680px) {
    .app__learningcard {
        width: 100%;
        margin-bottom: 2rem;
        padding: 2.3rem 1rem 1em;
    }

    .app__learningcard_illustration {
        width: 32%;
    }

    .app__learningcard_information-description {
        font-size: 16px;
        line-height: 25px;
    }

    .app__learningcard_navigation {
        width: 40%;
        margin-top: 0.9rem;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1500px) {
    .app__learningcard {
        margin-bottom: 2rem;
        padding: 2rem 1rem;
    }

    .app__learningcard_illustration {
        width: 35%;
        margin: 0 1rem 0 0;
    }
    
    .app__learningcard_information {
        width: 60%;
    }
}



@media screen and (max-width: 1380px) {
    .app__learningcard {
        margin-bottom: 2rem;
        padding: 2rem 1rem;
    }

    .app__learningcard_illustration {
        width: 40%;
    }

    .app__learningcard_navigation button {
        padding: 0.8rem 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 1280px) {
    .app__learningcard {
        margin-bottom: 3rem;
        padding: 2rem 1rem
    }

    .app__learningcard_illustration {
        width: 40%;
    }

    .app__learningcard_information-title {
        margin: 0rem 0 0.6rem;
    }

    .app__learningcard_navigation button {
        padding: 0.8rem 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 1150px) {
    .app__learningcard {
        width: 100%;
        margin-bottom: 1.8rem;
        padding: 1.5rem 1rem;
    }

    .app__learningcard_illustration {
        width: 35%;
        margin-right: 1rem;
    }

    .app__learningcard_information-description {
        font-size: 16px;
        line-height: 22px;
    }

    .app__learningcard_navigation {
        width: 25%;
    }

    .app__learningcard_navigate {
        margin-top: 1rem;
        font-size: 16px;
    }

    .dropdown_arrow {
        font-size: 1.2rem !important;
    }
}



@media screen and (max-width: 850px) {
    .app__learningcard {
        margin-bottom: 2.5rem;
    }

    .app__learningcard_informationcontainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .app__learningcard_illustration {
        width: 50%;
    }

    .app__learningcard_information {
        width: 100%;
        text-align: center;
    }

    .app__learningcard_information-title {
        margin: 1rem auto 0.5rem;
    }

    .app__learningcard_information-description {
        font-size: 14px;
        line-height: 20px;
    }

    .app__learningcard_navigation {
        width: 32%;
        margin-top: 0rem;
    }

    .app__learningcard_navigation button {
        padding: 1rem 0;
        font-size: 16px;
    }

    .app__learningcard_navigate {
        margin: 1rem auto 0;
    }

    .dropdown_arrow {
        font-size: 1rem !important;
    }
}



@media screen and (max-width: 700px) {
    .app__learningcard_navigation {
        margin-top: 1rem;
    }
}



@media screen and (max-width: 650px) {
    .app__learningcard {
        margin-bottom: 2rem;
        align-items: center;
    }

    .app__learningcard_informationcontainer {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .app__learningcard_illustration {
        width: 40%;
    }
    
    .app__learningcard_information {
        width: 60%;
        text-align: left;

        justify-content: flex-start;
        align-items: flex-start;
    }

    .app__learningcard_information-title {
        margin: 1rem 0 0.5rem;
    }

    .app__learningcard_information-description {
        font-size: 15px;
        line-height: 22px;
    }

    .app__learningcard_navigation {
        width: 60%;
        margin-top: 2rem;
    }

    .app__learningcard_navigation button {
        padding: 0.4rem 0;
        font-size: 14px;
    }

    .app__learningcard_navigate {
        margin: 1rem 0 0;
    }

    .dropdown_arrow {
        font-size: 1rem !important;
    }

    .app__learningcard_navigate:hover .dropdown_arrow {
        color: var(--color-darkblue);
        margin-left: 0.1rem;
        transition: 0.3s;
    }
}



@media screen and (max-width: 500px) {
    .app__learningcard {
        margin-bottom: 1rem;
    }

    .app__learningcard_illustration {
        width: 40%;
    }
    
    .app__learningcard_information {
        width: 60%;
    }

    .app__learningcard_information-title {
        margin: 0rem 0 0.5rem;
    }

    .app__learningcard_navigate {
        margin: 1rem 0 0;
    }

    .dropdown_arrow {
        font-size: 0.8rem !important;
    }
}



@media screen and (max-width: 450px) {
    .app__learningcard_illustration {
        width: 80%;
        margin-bottom: 1rem;
    }

    .app__learningcard_information {
        width: 100%;
    }
    
    .app__learningcard_information-description {
        font-size: 14px;
        line-height: 20px;
    }

    .app__learningcard_navigation {
        width: 70%;
    }

    .app__learningcard_navigation button {
        margin-top: 1rem;
        padding: 0.6rem;
        font-size: 14px;
    }
}



@media screen and (max-width: 400px) {
    .app__learningcard_informationcontainer {
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .app__learningcard_illustration {
        width: 60%;
    }
    
    .app__learningcard_information {
        width: 90%;
        text-align: center;
    }
    
    .app__learningcard_information-title {
        margin: 0rem auto 0.6rem;
    }
    
    .app__learningcard_information-description {
        font-size: 14px;
        line-height: 20px;
    }

    .app__learningcard_navigate {
        margin: 1.5rem auto 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 350px) {
    .app__learningcard_illustration {
        width: 50%;
    }

    .app__learningcard_information-description {
        font-size: 12px;
        line-height: 18px;
    }

    .app__learningcard_navigate {
        margin: 1rem auto 0;
        font-size: 14px;
    }

    .app__learningcard_navigation button {
        margin-top: 0rem;
        padding: 0.6rem;
        font-size: 12px;
    }
}
