.app__registration {
    width: 100%;
}

.app__registration p {
    width: 100%;
    height: 100%;
}

.app__registration_event {
    width: 100%;
    margin: 0 0 3rem;

    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.app__registration_event-image {
    width: 70%;
    height: 34rem;
    border-radius: 4px;
}

.app__registration_event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.app__registration_event-info-container {
    width: 25%;
    height: 34rem;
    padding: 3rem;
    background-color: var(--color-lightblue);
    border-radius: 4px;
}

.app__registration_event-info {
    width: 100%;
}

.app__registration_event-info-title {
    width: 100%;
    margin-bottom: 2rem;
}

.app__registration_event-info-eventdetails {
    width: 100%;
    margin-bottom: 1.8rem;
}

.app__registration_event-info-eventdetails img {
    width: 1.4rem;
    margin-right: 1.5rem;
}

.app__registration_event-info-register,
.app__registration_event-info-concluded {
    width: 90%;
    height: fit-content;
    margin: 5rem auto 0;
    display: block;
}

.app__registration_event-info-register button,
.app__registration_event-info-concluded button {
    width: 100%;
    padding: 1.2rem 0;
    border: none;
    border-radius: 3px;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    background-color: var(--color-yellow);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
    cursor: pointer;
}

.app__registration_event-info-concluded button {
    background-color: rgba(255, 0, 0, 0.8);
}

.app__registration_event-info-register button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.app__registration_event-info-register button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.app__registration_event-info-concluded button:active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
    animation: Restricted 0.2s linear 1;
}

.app__registration_about {
    margin-bottom: 2rem;
}

.app__registration_aboutdescription ul,
.app__registration_aboutdescription ol {
    margin: 1rem 0 1rem 3rem;
}

.app__registration_aboutdescription ul {
    list-style-type: square;
}

.app__registration_details {
    width: 100%;
    margin-top: 3rem;

    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.app__registration_details b {
    font-weight: 600;
}

.app__registration_details-itinerary,
.app__registration_details-venue {
    width: 40%;
    margin-bottom: 2rem;
}

.app__registration_details-itinerary {
    margin-right: 10rem;
}

.app__registration_details-itinerary > p,
.app__registration_details-venue > p {
    margin-bottom: 2rem;
}

.app__registration_details-itinerary-info,
.app__registration_details-venue-info {
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.app__registration_details-itinerary-info p:first-child,
.app__registration_details-venue-info p:first-child {
    margin-right: 2rem;
}

.app__registration_details-itinerary-info p:first-child {
    width: 25%;
}

.app__registration_details-venue-info p:nth-child(1) {
    width: 30%;
}

.app__registration_details-venue-info p:nth-child(2) {
    width: 15%;
}



@media screen and (min-width: 2000px) {
    .app__registration_event-image {
        width: 60%;
        height: 45rem;
    }

    .app__registration_event-info-container {
        width: 35%;
        height: 45rem;
    }

    .app__registration_event-info-register,
    .app__registration_event-info-concluded {
        margin: 3rem auto 0;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        width: 100%;
        padding: 1.4rem 0;
        font-size: 25px;
    }

    .app__registration_details-itinerary,
    .app__registration_details-venue {
        width: 50%;
    }

    .app__registration_details-itinerary-info p:first-child {
        width: 30%;
    }
    
    .app__registration_details-venue-info p:nth-child(1) {
        width: 40%;
    }
    
    .app__registration_details-venue-info p:nth-child(2) {
        width: 20%;
    }
}



@media screen and (min-width: 2200px) {
    .app__registration_event-image {
        width: 60%;
        height: 35rem;
    }

    .app__registration_event-info-container {
        width: 35%;
        height: 35rem;
    }
}



@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .app__registration_details-itinerary {
        width: 60%;
    }

    .app__registration_details-venue {
        width: 40%;
    }

    .app__registration_details-itinerary-info p:first-child {
        width: 30%;
    }
    
    .app__registration_details-venue-info p:nth-child(1) {
        width: 50%;
    }
    
    .app__registration_details-venue-info p:nth-child(2) {
        width: 20%;
    }
}



@media screen and (max-width: 1600px) {
    .app__registration_details {
        flex-direction: column;
    }

    .app__registration_details-itinerary {
        width: 80%;
        margin-top: 3rem;
    }

    .app__registration_details-venue {
        width: 80%;
        margin-top: 3rem;
    }
}



@media screen and (min-width: 1400px) and (max-width: 1700px) {
    .app__registration_event-image {
        width: 60%;
        height: 32rem;
    }

    .app__registration_event-info-container {
        width: 35%;
        height: 32rem;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        width: 100%;
        padding: 1rem 0;
        font-size: 18px;
    }
}



@media screen and (min-width: 1150px) and (max-width: 1400px) {
    .app__registration_event-image {
        width: 40%;
        height: 30rem;
    }

    .app__registration_event-info-container {
        width: 55%;
        height: 30rem;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        width: 100%;
        padding: 1rem 0;
        font-size: 18px;
    }
}



@media screen and (max-width: 1150px) {
    .app__registration_event-image {
        width: 45%;
        height: 25rem;
        margin-bottom: 1rem;
    }

    .app__registration_event-info-container {
        width: 50%;
        height: 25rem;
    }

    .app__registration_event-info-register,
    .app__registration_event-info-concluded {
        margin: 3rem auto 0;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        margin: 0 0 0;
        padding: 0.8rem 0;
        font-size: 18px;
    }

    .app__registration_aboutdescription ul,
    .app__registration_aboutdescription ol {
        margin: 1rem 0 1rem 2rem;
    }

    .app__registration_details-itinerary {
        width: 100%;
        margin: 1rem 0;
    }

    .app__registration_details-venue {
        width: 100%;
        margin: 1rem 0;
    }

    .app__registration_details-itinerary-info p:first-child {
        width: 24%;
    }
    
    .app__registration_details-venue-info p:nth-child(1) {
        width: 40%;
    }
    
    .app__registration_details-venue-info p:nth-child(2) {
        width: 20%;
    }
}



@media screen and (max-width: 850px) {
    .app__registration_event {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .app__registration_event-image {
        width: 100%;
        height: 25rem;
        margin-bottom: 1rem;
    }

    .app__registration_event-info-container {
        width: 100%;
        height: 25rem;
    }

    .app__registration_details-itinerary,
    .app__registration_details-venue {
        margin: 1rem 0 0;
    }

    .app__registration_event-info-register,
    .app__registration_event-info-concluded {
        width: 50%;
        margin: 3rem 0 0;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        padding: 1rem 0;
        font-size: 16px;
    }

    .app__registration_details-itinerary-info p:first-child {
        width: 30%;
    }
    
    .app__registration_details-venue-info p:nth-child(1) {
        width: 50%;
    }
    
    .app__registration_details-venue-info p:nth-child(2) {
        width: 20%;
    }
}



@media screen and (max-width: 650px) {
    .app__registration_event-image {
        width: 100%;
        height: 20rem;
        margin-bottom: 1rem;
    }

    .app__registration_event-info-container {
        height: fit-content;
        padding: 2rem 1rem;
    }

    .app__registration_details-itinerary,
    .app__registration_details-venue {
        margin: 1rem 0 0;
    }

    .app__registration_details-itinerary-info,
    .app__registration_details-venue-info {
        flex-direction: column;
    }

    .app__registration_details-itinerary-info {
        margin-bottom: 1rem;
    }

    .app__registration_details-itinerary-info p:nth-child(1) {
        margin-bottom: 0.4rem;
    }

    .app__registration_details-venue-info p:nth-child(2) {
        display: none;
    }

    .app__registration_event-info-register button:hover {
        transform: scale(1.01);
    }
}



@media screen and (max-width: 480px) {
    .app__registration_event-info-register,
    .app__registration_event-info-concluded {
        width: 60%;
    }
}



@media screen and (max-width: 450px) {
    .app__registration_event-image {
        width: 100%;
        height: 15rem;
        margin-bottom: 1rem;
    }

    .app__registration_event-info-container {
        height: fit-content;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        margin-top: 0;
        padding: 0.8rem 0;
        font-size: 16px;
    }
}



@media screen and (max-width: 360px) {
    .app__registration_event-info-register,
    .app__registration_event-info-concluded {
        width: 70%;
    }

    .app__registration_event-info-register button,
    .app__registration_event-info-concluded button {
        font-size: 14px;
    }
}