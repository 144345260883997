.app__events {
    width: 100%;
    background-color: var(--color-white);
}

.app__events-carousel {
    width: 100%;
}

.app__events-carousel .react-multiple-carousel__arrow {
    position: absolute;
    top: 41%;
    outline: 0;
    transition: all 0.5s;
    border-radius: 50%;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 45px;
    min-height: 45px;
    opacity: 1;
    cursor: pointer;
}

.app__events-carousel .react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}

.app__events-carousel .react-multiple-carousel__arrow::before {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.app__events-carousel .react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0, 0, 0, 0.5);
}

.app__events-carousel .react-multiple-carousel__arrow--left {
    left: calc(0.2% + 1px);
}

.app__events-carousel .react-multiple-carousel__arrow--left::before {
    content: "\e824";
}

.app__events-carousel .react-multiple-carousel__arrow--right {
    right: calc(0.2% + 1px);
}

.app__events-carousel .react-multiple-carousel__arrow--right::before {
    content: "\e825";
}

.app__events-carousel .react-multi-carousel-dot button {
    width: 13px;
    height: 13px;
}

.app__events-content {
    width: 100%;
    height: fit-content !important;
    margin: 3rem 0 3rem;
    background-color: var(--color-lightblue);
    border-radius: 4px;
    align-items: flex-start;
    text-align: left;
}

.app__events-content_image {
    width: 60%;
    height: 36rem;
    border-radius: 4px;
    background-color: var(--color-white);
    overflow: hidden;
}

.app__events-content_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__events-content_info {
    width: 40%;
    height: 36rem;
    padding: 3rem;
    line-height: 40px;
}

.app__events-content_info-details span {
    font-weight: 500;
}

.app__events-button {
    margin: 1rem auto 3rem;
    border-radius: 4px;
    display: block;
    transition: 0.1s;
}

.app__events-button button {
    margin: auto;
    padding: 1rem 1.8rem;
    color: var(--color-black);
    background-color: var(--color-white);
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s;
}

.app__events-button button:hover {
    transform: scale(1.02);
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
}

.app__events-button button:active {
    transform: scale(1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}



@media screen and (min-width: 2000px) {
    .app__events-carousel .react-multiple-carousel__arrow {
        min-width: 60px;
        min-height: 60px;
    }

    .app__events-carousel .react-multiple-carousel__arrow::before {
        font-size: 30px;
    }

    .app__events-carousel .react-multiple-carousel__arrow--left {
        left: calc(1% + 1px);
    }
    
    .app__events-carousel .react-multiple-carousel__arrow--right {
        right: calc(1% + 1px);
    }

    .app__events-content_image {
        height: 60rem;
    }

    .app__events-content_info {
        height: 60rem;
        padding: 5rem;
    }

    .app__events-button button {
        padding: 1rem 1.8rem;
        font-size: 22px;
    }
}



@media screen and (min-width: 1550px) and (max-width: 1780px) {

    .app__events-content_image {
        height: 40rem;
    }

    .app__events-content_info {
        height: 40rem;
    }
}



@media screen and (max-width: 1550px) {
    .app__events-content_image {
        height: 45rem;
    }
    
    .app__events-content_info {
        height: 45rem;
        line-height: 55px;
    }
}



@media screen and (max-width: 1380px) {
    .app__events-content_image {
        width: 100%;
        height: 38rem;
        border-radius: 0;
    }
    
    .app__events-content_info {
        width: 100%;
        height: 38rem;
    }
}



@media screen and (max-width: 1200px) {
    .app__events-button button {
        padding: 1rem 1.8rem;
    }

    .app__events-content_image {
        height: 40rem;
    }

    .app__events-content_info {
        height: 40rem;
        padding: 2rem;
    }

    .app__events-carousel .react-multiple-carousel__arrow {
        min-width: 40px;
        min-height: 40px;
    }

    .app__events-carousel .react-multiple-carousel__arrow::before {
        font-size: 18px;
    }

    .app__events-carousel .react-multiple-carousel__arrow--left {
        left: calc(0.5% + 1px);
    }
    
    .app__events-carousel .react-multiple-carousel__arrow--right {
        right: calc(0.5% + 1px);
    }
}



@media screen and (max-width: 850px) {
    .app__events-content {
        margin: 2rem 0 2rem;
        flex-direction: column;
    }

    .app__events-content_image {
        height: 20rem;
    }

    .app__events-content_info {
        height: 26rem;
        padding: 2rem;
    }

    .app__events-button button {
        padding: 0.8rem 1.5rem;
        font-size: 16px;
    }

    .app__events-carousel .react-multi-carousel-dot button {
        width: 12px;
        height: 12px;
    }
}



@media screen and (max-width: 650px) {
    .app__events-content_image {
        height: 15rem;
    }

    .app__events-content_info {
        height: 25rem;
        padding: 1.5rem;
        overflow-y: scroll;
    }

    .app__events-content_info::-webkit-scrollbar {
        width: 5px;
    }

    .app__events-content_info::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .app__events-content_info::-webkit-scrollbar-thumb {
        background: #88caf0;
        border-radius: 10px;
    }

    .app__events-button button {
        padding: 1rem 1.5;
    }

    .app__events-button button:hover {
        transform: scale(1.01);
    }
}



@media screen and (max-width: 480px) {
    .app__events-button {
        margin: 0rem auto 3rem;
    }
}



@media screen and (max-width: 450px) {
    .app__events-content_info {
        line-height: 30px;
    }

    .app__events-button button {
        padding: 0.8rem 1.3rem;
        font-size: 14px;
    }

    .app__events-carousel .react-multi-carousel-dot button {
        width: 10px;
        height: 10px;
    }
}
